import React, {useEffect, useState} from 'react';
import Template from "../Base/Template";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import SaveIcon from '@material-ui/icons/Save';
import Divider from "@material-ui/core/Divider";
import {connect} from "react-redux";
import {fetchSettings, updateSettings} from "../../store/actions/settingsActions";
import {useSnackbar} from "notistack";
import TestMail from "./TestMail";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    field: {
        marginBottom: '20px'
    },
    button: {
        maxWidth: '300px'
    }
}));

const Settings = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const fields = [
        {
            name: 'email_from_address',
            label: t('pages:settings:form.email_from')
        },
        {
            name: 'email_smtp_server',
            label: t('pages:settings:form.email_smtpserver')
        },
        {
            name: 'email_smtp_username',
            label: t('pages:settings:form.email_smtpusername')
        },
        {
            name: 'email_smtp_password',
            label: t('pages:settings:form.email_smtppassword')
        },
        {
            name: 'email_smtp_port',
            label: t('pages:settings:form.email_smtpport')
        },
        {
            name: 'email_smtp_encryption',
            label: t('pages:settings:form.email_smtpencryption'),
            choices: ["tls", "ssl", "none"],
        },
        {
            name: 'email_smtp_authmode',
            label: t('pages:settings:form.email_smtpauthmode'),
            choices: ["login", "plain", "cram-md5"],
        },
        {
            name: 'email_verification_url_template',
            label: t('pages:settings:form.email_verification_urltemplate'),
            help: t('pages:settings:form.email_verification_urltemplate_help'),
        },
        {
            name: 'email_passwordreset_url_template',
            label: t('pages:settings:form.email_passwordreset_urltemplate'),
            help: t('pages:settings:form.email_passwordreset_urltemplate_help'),
        },
        {
            name: 'email_transport_enabled',
            label: t('pages:settings:form.email_transportenabled'),
            checkbox: true,
        },
    ]

    const [state, setState] = useState({
        email_smtp_encryption: "tls",
        email_smtp_authmode: "login"
    });
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) =>  {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    };
    const handleCheckboxChange = (e) =>  {
        setState({
            ...state,
            [e.target.name]: e.target.checked
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        props.updateSettings(state);

        setSubmitted(true);
    }

    useEffect(() => {
        setSubmitted(false);
        props.fetchSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!props.settings.loading && !props.settings.error.code) {
            setState(props.settings.settings);

            if(submitted){
                enqueueSnackbar(t('pages:settings:notifier-success'), {variant: "success"});
            }
        }
        else if(!props.settings.loading && props.settings.error.code){
            if(submitted){
                if(props.settings.error.code === 422) {
                    enqueueSnackbar(t('pages:settings:notifier-configerror'), {variant: "warning"})
                }
                else {
                    enqueueSnackbar(t('pages:settings:notifier-error'), {variant: "error"});
                }
            }
            else {
                enqueueSnackbar(t('pages:settings:notifier-loading-error'), {variant: "error"});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.settings]);

    return (
        <Template title={t('pages:settings:title')}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Typography variant={"subtitle1"}>{t('pages:settings:description')}</Typography>
                    {fields.map((field, index) => {
                        if(field.choices) {
                            return (
                                <FormControl key={index}>
                                    <InputLabel id={field.name}>{field.label}</InputLabel>
                                    <Select
                                        className={classes.field}
                                        labelId={field.name}
                                        value={state[field.name] ?? field.choices[0]}
                                        name={field.name}
                                        onChange={handleChange}
                                    >
                                        {field.choices.map((choice, index) => {
                                            return (
                                                <MenuItem key={index} value={choice}>{choice}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            );
                        }
                        else if(field.checkbox) {
                            return (
                                <FormControlLabel
                                    key={index}
                                    className={classes.field}
                                    control={
                                        <Checkbox
                                            checked={state[field.name] ?? false}
                                            onChange={handleCheckboxChange}
                                            name={field.name}
                                        />}
                                    label={field.label}
                                />
                            )
                        }
                        else if(field.divider) {
                            return (
                                <Divider key={index} style={{marginTop: '15px', marginBottom: '15px'}} />
                            )
                        }
                        else {
                            return (
                                <TextField
                                    className={classes.field}
                                    key={index}
                                    label={field.label}
                                    value={state[field.name] ?? ""}
                                    helperText={field?.help}
                                    name={field.name}
                                    onChange={handleChange}
                                />
                            );
                        }
                    })}
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        className={classes.button}
                        onClick={handleSubmit}
                        disabled={props.settings.loading}
                    >
                        {t('pages:settings:button.save')}
                    </Button>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <TestMail />
            </Grid>
        </Template>
    );
};

const mapStateToProps = (state) => {
    return {
        settings: state.settings,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSettings: () => dispatch(fetchSettings()),
        updateSettings: (data) => dispatch(updateSettings(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);