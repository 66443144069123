import React, {useEffect} from 'react';
import Template from "../../Base/Template";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useSnackbar} from "notistack";
import {fetchSingleEvent} from "../../../store/actions/singleEventActions";
import {LinearProgress} from "@material-ui/core";
import EventEdit from "./EventEdit";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    progress: {
        marginTop: '20px',
        marginBottom: '20px'
    }
}));

const EventEditLoader = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    let {id} = props.match.params;

    useEffect(() => {
        props.fetchEvent(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(props.event.loading) { return; }
        if(props.event?.error.code) {
            enqueueSnackbar(t('pages:event:edit:notifier-loading-error'), {variant: 'error'});
            return;
        }
        console.debug("Fetched data successfully.");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.event]);

    return (
        <Template title={t('pages:event:edit:title')}>
            {props.event.loading ?
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <LinearProgress color={"primary"} className={classes.progress} />
                </Paper>
            </Grid>
                :
                !props.event.error.code &&
                <EventEdit data={props.event.event} id={id} />
            }
        </Template>
    );
};

const mapStateToProps = (state) => {
    return {
        event: state.singleEvent,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchEvent: (id) => dispatch(fetchSingleEvent(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventEditLoader);