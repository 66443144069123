import axios from "axios";
import axiosRetry from 'axios-retry';

export let baseUrl;
if(process.env.API_BASE_URL) {
    baseUrl = process.env.API_BASE_URL;
} else if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    baseUrl = "https://localdev.livestream-app.de:8001/api";
} else {
    baseUrl = "https://staging.livestream-api.de/api";
}

let UPLOAD_SVC_BASEURL;
if(process.env.API_BASE_URL) {
    UPLOAD_SVC_BASEURL = process.env.API_BASE_URL;
} else if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    UPLOAD_SVC_BASEURL = "http://localhost:3000";
} else {
    UPLOAD_SVC_BASEURL = "https://upload.svc.livestream-api.de";
}


export const uploadSvcClient = axios.create({
    withCredentials: true,
    responseType: "json",
    timeout: 3 * 60 * 1000,
    baseURL: UPLOAD_SVC_BASEURL
});
axiosRetry(uploadSvcClient, {
    retries: 3,
    retryDelay: (retryCount) => {
        let delay = Math.pow(2, retryCount) * 1000;
        if(delay >= 64000) { delay = 64000; }
        console.log(`retry attempt: ${retryCount}`, `Delay: ${delay}`);
        return delay;
    },
})


export default axios.create({
    withCredentials: true,
    responseType: "json",
    timeout: 10000,
    baseURL: baseUrl,
});
