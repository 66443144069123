import API from "../../utils/Api";
import {getToken} from "../../helper/AuthHelper";
import {
    FETCH_EVENT_REGISTRATIONS,
    FETCH_EVENT_REGISTRATIONS_FAILURE,
    FETCH_EVENT_REGISTRATIONS_SUCCESS
} from "../types/eventRegistrationTypes";

export const fetchEventRegistrations = (id) => {

    return (dispatch) => {
        dispatch(fetchEventRegistrationsStart());
        API.get(`/v2/events/${id}/registrations`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
            .then((response) => {
                dispatch(fetchEventRegistrationsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchEventRegistrationsFailure(error));
            })
    }
}

export const fetchEventRegistrationsSuccess = (data) => {
    return {
        type: FETCH_EVENT_REGISTRATIONS_SUCCESS,
        registrations: data.users,
    }
};

export const fetchEventRegistrationsStart = () => {
    return {
        type: FETCH_EVENT_REGISTRATIONS
    };
}

export const fetchEventRegistrationsFailure = (error) => {
    return {
        type: FETCH_EVENT_REGISTRATIONS_FAILURE,
        errMsg: error.message,
        errCode: error.response ? error.response.status ?? -1 : -1,
    }
}

