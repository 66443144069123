import {connect} from "react-redux";
import {Grid, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import api from "../../../utils/Api";
import {useEffect, useState} from "react";
import {DateTime, Duration} from "luxon";

import useInterval from "../../../utils/hooks/useInterval";
import React from "react";
import DataTable from "../../../components/DataTable";
import i18n from "i18next";
import {DATETIME_MED_WITH_SECONDS} from "luxon/src/impl/formats";
import {getToken} from "../../../helper/AuthHelper";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  chip: {
    animation: `$pulse 7s ease 0s infinite normal forwards`
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)"
    },
    "5%": {
      transform: "scale(1.1)"
    },
    "10%": {
      transform: "scale(1)"
    },
    "100%": {
      transform: "scale(1)"
    },
  }
}));

const MediaStats = (props) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const [viewers, setViewers] = useState([]);
  const [lastUpdate, setLastUpdate] = useState("");

  const fetchNewData = () => {
    api.get(`/v2/events/${props.event.id}/archive_stats`, {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    })
      .then((r) => {
        setViewers(Array.from(r.data.viewers));
      })
      .catch((e) => {
        console.error(e);
      })
    ;
    const dt = DateTime.now();
    setLastUpdate(dt.setLocale(i18n.language).toLocaleString(DATETIME_MED_WITH_SECONDS));
  }

  /* Fetch fresh data once on view load */
  useEffect(() => {
    fetchNewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => {
    fetchNewData();
  }, 30 * 1000);

  const columns = [
    {
      selector: row => row['user']['first_name'],
      name: t('pages:event:stats:archive:viewers:first_name'),
      sortable: true
    },
    {
      selector: row => row['user']['last_name'],
      name: t('pages:event:stats:archive:viewers:last_name'),
      sortable: true
    },
    {
      selector: row => row['user']['company_name'],
      name: t('pages:event:stats:archive:viewers:company_name'),
      sortable: true
    },
    {
      selector: row => row['watch_time'],
      name: t('pages:event:stats:archive:viewers:watch_time'),
      sortable: true,
      cell: ({watch_time}) => {
        return Duration.fromObject({seconds: watch_time}).toFormat('hh:mm:ss')
      }
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} style={{marginTop: 15}}>
        <Paper className={classes.paper} style={{
          textAlign: 'left',
          marginBottom: 15
        }}>
          <DataTable
            title={t('pages:event:stats:archive:viewers:table-heading')}
            columns={columns}
            data={viewers}
            denseLayout={true}
            showLayoutSwitch={false}
            searchFields={['first_name', 'last_name', 'company_name']}
          />
          <Typography variant={"subtitle2"}>
          {t('pages:event:stats:livestats:last-update')} {lastUpdate}
        </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    event: state.singleEvent.event
  };
};


export default connect(mapStateToProps)(MediaStats);
