import React, {useEffect, useState} from "react";
import {Checkbox, Divider, FormControlLabel, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import LuxonUtils from '@date-io/luxon';
import {Alert} from "@material-ui/lab";
import {DateTime} from "luxon";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main
    },
    fieldInput: {
        marginTop: '10px',
    },
    divider: {
        marginTop: '5px',
        marginBottom: '10px',
    }
}));

const Time = ({setTime, values}) => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();

    const [startDate, handleStartDateChange] = useState(values?.start_date ?? DateTime.now());
    const [endDate, handleEndDateChange] = useState(values?.end_date ?? DateTime.now().plus({hours: 1}));
    const [endDateHidden, setEndDateHidden] = useState(values?.end_date_hidden ?? false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const timeDiff = endDate - startDate;

        if(timeDiff < 0) {
            setError("END_BEFORE_START");
        }
        else if(timeDiff === undefined) {
            setError("INVALID_DATE");
        }
        else if(startDate.invalid || endDate.invalid){
            setError("INVALID_DATE");
        }
        else {
            setError(null);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        setTime({
            completed: error === null,
            values: {
                start_date: startDate,
                end_date: endDate,
                end_date_hidden: endDateHidden
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, endDateHidden, error]);

    const userTimezoneString = DateTime.now().setLocale(i18n.language).toFormat("ZZZZZ");

    return (
        <div style={{marginTop: '30px'}}>
            <Typography variant={"h4"} className={classes.title}>{t('pages:event:create:information:time:title')}</Typography>
            <Divider className={classes.divider} />
            <Typography variant={"subtitle2"}>{t('pages:event:create:information:time:notice-timezone', {timezone: userTimezoneString})}</Typography>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <KeyboardDateTimePicker
                    name={"start_date"}
                    value={startDate}
                    onChange={handleStartDateChange}
                    label={t('pages:event:create:information:time:form.start_date')}
                    disableToolbar={true}
                    format={"dd.MM.yyyy HH:mm"}
                    fullWidth
                    className={classes.fieldInput}
                />
                <KeyboardDateTimePicker
                    name={"end_date"}
                    value={endDate}
                    onChange={handleEndDateChange}
                    label={t('pages:event:create:information:time:form.end_date')}
                    disableToolbar={true}
                    format={"dd.MM.yyyy HH:mm"}
                    fullWidth
                    className={classes.fieldInput}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={endDateHidden}
                            onChange={() => {setEndDateHidden(!endDateHidden)}}
                            name={"end_date_hidden"}
                            color="primary"
                        />
                    }
                    label={t('pages:event:create:information:time:form.end_date_hidden')}
                />

            </MuiPickersUtilsProvider>
            {error === "END_BEFORE_START" &&
                <Alert
                    severity="warning"
                    style={{marginTop: '10px'}}
                >
                    {t('pages:event:create:information:time:error.end_before_start')}
                </Alert>
            }
            {error === "INVALID_DATE" &&
            <Alert
                severity="warning"
                style={{marginTop: '10px'}}
            >
                {t('pages:event:create:information:time:error.invalid_date')}
            </Alert>
            }
        </div>
    );
}

export default Time;