import {connect} from "react-redux";
import {Chip, Grid, Paper, Typography, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import api from "../../../utils/Api";
import {useEffect, useState} from "react";
import {CartesianGrid, ResponsiveContainer, XAxis, YAxis, AreaChart, Area} from "recharts";
import {DateTime} from "luxon";
import {
  DATETIME_MED_WITH_SECONDS,
} from "luxon/src/impl/formats";
import i18n from "i18next";
import useInterval from "../../../utils/hooks/useInterval";
import React from "react";
import {Alert, AlertTitle} from "@material-ui/lab";
import {fetchSingleEvent} from "../../../store/actions/singleEventActions";
import LivestreamLiveViewers from "./LivestreamLiveViewers";
import {getToken} from "../../../helper/AuthHelper";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  chip: {
    animation: `$pulse 7s ease 0s infinite normal forwards`
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)"
    },
    "5%": {
      transform: "scale(1.1)"
    },
    "10%": {
      transform: "scale(1)"
    },
    "100%": {
      transform: "scale(1)"
    },
  }
}));

const LivestreamLiveStats = (props) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const [liveData, setLiveData] = useState([]);
  const [lastCount, setLastCount] = useState(0);
  const [lastUpdate, setLastUpdate] = useState("");
  const [liveViewers, setLiveViewers] = useState([]);
  const theme = useTheme();

  const fetchNewData = () => {
    api.get(`/v2/events/${props.event.id}/live_stats?detailed`, {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    })
      .then((r) => {
        let newLiveData = liveData;

        newLiveData.push({
          time: new Date().toLocaleTimeString("de-DE"),
          count: r.data.count,
        });

        if(newLiveData.length > 20) {
          setLiveData(Array.from(newLiveData.slice(-20)));
        }
        else {
          setLiveData(Array.from(newLiveData));
        }
        setLastCount(r.data.count);
        setLiveViewers(Array.from(r.data.viewers));

        const dt = DateTime.now();
        setLastUpdate(dt.setLocale(i18n.language).toLocaleString(DATETIME_MED_WITH_SECONDS));
      })
      .catch((e) => {
        console.error(e);
      })
    ;
  }

  /* Fetch fresh data once on view load */
  useEffect(() => {
    fetchNewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => {
    fetchNewData();
    props.fetchEvent(props.event.id);
  }, 10 * 1000);

  return (
    <Grid container>
      {props.event.media_data?.started ?
      <>
        <Grid item xs={12} md={4} lg={3}>
          <Typography variant={"h6"} component={"h1"} style={{marginBottom: 15}}>
            {t('pages:event:stats:livestats:title')}
            <Chip className={classes.chip} label={t('pages:event:stats:livestats:chips:live')} color={"primary"} size={"small"} style={{marginLeft: 10}}></Chip>
          </Typography>
          <Paper className={classes.paper} style={{
            textAlign: 'center',
            marginBottom: 15
          }}>
            <Typography
              style={{
                fontSize: '3em',
                lineHeight: '1em'
              }}
            >
              {lastCount}
            </Typography>
            <br />
            {t('pages:event:stats:livestats:cards:live_viewers')}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant={"h6"} component={"h1"} style={{marginBottom: 15}}>
              {t('pages:event:stats:livestats:table-title')}
            </Typography>
            <ResponsiveContainer width={"100%"} height={300}>
              <AreaChart
                width={500}
                height={300}
                data={liveData}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time"  />
                <YAxis allowDecimals={false} />
                <Area
                  type="monotone"
                  dataKey="count"
                  stroke={theme.palette.primary.main}
                  fill={theme.palette.primary.light}
                  animationDuration={700}
                />
              </AreaChart>
            </ResponsiveContainer>
            <Typography variant={"subtitle2"}>
              {t('pages:event:stats:livestats:last-update')} {lastUpdate}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} style={{marginTop: 15}}>
          <Paper className={classes.paper}>
            <LivestreamLiveViewers viewers={liveViewers} />
          </Paper>
        </Grid>
      </>
        :
        <Grid item xs={12}>
          <Typography variant={"h6"} component={"h1"} style={{marginBottom: 15}}>
            {t('pages:event:stats:livestats:title')}
            <Chip className={classes.chip} label={t('pages:event:stats:livestats:chips:notlive')} size={"small"} style={{marginLeft: 10}}></Chip>
          </Typography>
          <Alert severity={"info"}>
            <AlertTitle>{t('pages:event:stats:livestats:title-notlive')}</AlertTitle>
            <Typography>{t('pages:event:stats:livestats:message-notlive')}</Typography>
          </Alert>
        </Grid>
      }
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    event: state.singleEvent.event
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEvent: (id) => dispatch(fetchSingleEvent(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LivestreamLiveStats);
