import {Link} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {Link as RouterLink} from "react-router-dom";
import DataTable from "../../../components/DataTable";
import {useConfirm} from "material-ui-confirm";
import {getTenant, getToken} from "../../../helper/AuthHelper";
import Api from "../../../utils/Api";
import {useSnackbar} from "notistack";
import {fetchTemplates} from "../../../store/actions/emailTemplatesActions";

const EmailListTable = (props) => {
    const {t} = useTranslation();
    const confirm = useConfirm();
    const {enqueueSnackbar} = useSnackbar();

    const handleConfirmClick = (id) => {
        console.log(id);
        confirm({
            title: t('pages:settings:email:list:confirm.title'),
            description: t('pages:settings:email:list:confirm.description'),
            cancellationText: t('globals:buttons:cancel'),
        })
            .then(() => handleDeletion(id));
    };

    const handleDeletion = async (id) => {
        const tenantId = getTenant().id;

        try {
            const response = await Api.delete(`/v2/tenants/${tenantId}/email_templates/${id}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            });

            if(response.status === 200){
                enqueueSnackbar(t('pages:settings:email:list:notifier.delete-success'), {variant: "success"});
                props.fetchTemplates();
            }
        }
        catch(e) {
            enqueueSnackbar(t('pages:settings:email:list:notifier.delete-failure'), {variant: "error"});
        }
    }

    const columns = [
        {
            selector: row => row['id'],
            name: t('pages:settings:email:list:table.id'),
            sortable: true
        },
        {
            cell: (data) => {
                const type = data.type;
                switch(type){
                    case "event_registration":
                        return t('pages:settings:email:list:type.event_registration');
                    case "event_reminder":
                        return t('pages:settings:email:list:type.event_reminder');
                    case "user_verification":
                        return t('pages:settings:email:list:type.user_verification');
                    case "user_password_reset":
                        return t('pages:settings:email:list:type.user_password_reset');
                    default:
                        return "Unknown";
                }
            },
            name: t('pages:settings:email:list:table.type'),
            sortable: false,
        },
        {
            selector: row => row['language'],
            name: t('pages:settings:email:list:table.language'),
            sortable: true,
        },
        {
            cell: (data) => data.active === true ? t('pages:settings:email:list:table.state-active') : t('pages:settings:email:list:table.state-inactive'),
            name: t('pages:settings:email:list:table.state'),
            sortable: true,
        },
        {
            cell: (data) => <>
                <Link component={RouterLink} to={`/settings/email-templates/${data.id}`} color={"textPrimary"}><EditIcon /></Link>&nbsp;
                <DeleteForeverIcon color={"error"} onClick={() => {handleConfirmClick(data.id)}} />
            </>,
            button: true,
        },
    ];

    return (
        <div style={{ width: '100%' }}>
            <DataTable
                title={t('pages:settings:email:list:table.heading')}
                columns={columns}
                data={props.templatesList.templates}
                denseLayout={false}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        templatesList: state.emailTemplates,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTemplates: () => dispatch(fetchTemplates())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailListTable);
