import {FETCH_EVENTS, FETCH_EVENTS_FAILURE, FETCH_EVENTS_SUCCESS} from "../types/eventsTypes";

const initialState = {
    loading: true,
    error: {},
    events: [],
};

const eventsReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_EVENTS:
            return {
                ...state,
                events: [],
                loading: true,
            };

        case FETCH_EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                events: action.events,
                error: {},
            };
        case FETCH_EVENTS_FAILURE:
            return {
                ...state,
                loading: false,
                events: [],
                error: {
                    code: action.errCode,
                    message: action.errMsg,
                }
            };
        default:
            return state;
    }
}

export default eventsReducer;
