import React, {useState} from "react";
import {Divider, MenuItem, Select, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {useDebouncedEffect} from "../../../../../helper/useDebouncedEffect";
import ChipInput from "material-ui-chip-input";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main
    },
    fieldInput: {
        marginTop: '10px'
    },
    divider: {
        marginTop: '5px',
        marginBottom: '10px',
    }
}));

const Meta = ({setMeta, values}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const [state, setState] = useState({
        language: values.language,
        tags: values.tags,
    });

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    const handleTagChange = (chips) => {
        setState({
            ...state,
            tags: chips
        });
    }

    useDebouncedEffect(() => {
        setMeta({
            completed: false,
            values: state,
        })
    }, [state], 300);

    return (
        <div style={{marginTop: '30px'}}>
            <Typography variant={"h4"} className={classes.title}>{t('pages:event:create:information:meta.title')}</Typography>
            <Divider className={classes.divider} />
            <Typography variant={"subtitle2"}>{t('pages:event:create:information:meta.form.language-help')}</Typography>
            <Select
                name={"language"}
                value={state.language ?? "de"}
                onChange={handleChange}
                fullWidth
            >
                <MenuItem value={"de"}>{t('globals:languages:german')}</MenuItem>
                <MenuItem value={"en"}>{t('globals:languages:english')}</MenuItem>
                <MenuItem value={"fr"}>{t('globals:languages:french')}</MenuItem>
                <MenuItem value={"it"}>{t('globals:languages:italian')}</MenuItem>
                <MenuItem value={"pl"}>{t('globals:languages:polish')}</MenuItem>
                <MenuItem value={"ru"}>{t('globals:languages:russian')}</MenuItem>
                <MenuItem value={"es"}>{t('globals:languages:spanish')}</MenuItem>
                <MenuItem value={"tr"}>{t('globals:languages:turkish')}</MenuItem>
            </Select>
            <ChipInput
                style={{marginTop: '20px'}}
                onChange={handleTagChange}
                defaultValue={state.tags ?? []}
                fullWidth
                label={t('pages:event:create:information:meta.form.tags')}
                helperText={t('pages:event:create:information:meta.form.tags-help')}
                newChipKeys={[","]}
                newChipKeyCodes={[13, 32]}
            />
        </div>
    );
}

export default Meta;
