import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import {useTranslation} from "react-i18next";
import {languages} from "../../translation/i18n";


export const LANGUAGE_SWITCHER_STORAGE_KEY = "user_language_preference";

const LanguageSwitcher = ({fullWidth = true}) => {

    const { t, i18n } = useTranslation();

    const [language, setLanguage] = useState("de");
    const handleLanguageSwitch = (e) => {
        if(languages.includes(e.target.value)) {
            i18n
                .changeLanguage(e.target.value)
                .then(() => {
                    setLanguage(i18n.language);
                    setSessionStorage(i18n.language);
                    console.debug("[i18n]", "Changed language to", i18n.language);
                });
        }
        else {
            console.warn("Could not change language as it is not available.");
        }
    }

    const setSessionStorage = (language) => {
        sessionStorage.setItem(LANGUAGE_SWITCHER_STORAGE_KEY, language);
    }

    /* Load the users language preference from storage if it is set */
    useEffect(() => {
        const lang = sessionStorage.getItem(LANGUAGE_SWITCHER_STORAGE_KEY);
        if(lang !== null && i18n.languages.includes(lang)) {
            setLanguage(lang);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(i18n.language === language){
            return;
        }

        i18n.changeLanguage(language)
            .then()
            .catch((e) => {
                console.error(e);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    return(
        <FormControl fullWidth={fullWidth} style={{width: fullWidth ? '100%' : '150px'}}>
            <InputLabel>{t('lngswitcher.title')}</InputLabel>
            <Select
                value={language}
                onChange={handleLanguageSwitch}
            >
                <MenuItem value={"de"}>{t('lngswitcher.german')} {getUnicodeFlagIcon('de')}</MenuItem>
                <MenuItem value={"en"}>{t('lngswitcher.english')} {getUnicodeFlagIcon('gb')}</MenuItem>
            </Select>
        </FormControl>
    )
};

export default LanguageSwitcher;