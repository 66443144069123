import React, { useState} from "react";
import {Divider, FormHelperText, InputAdornment, TextField, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {useDebouncedEffect} from "../../../../../helper/useDebouncedEffect";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main
    },
    fieldInput: {
        marginTop: '10px'
    },
    divider: {
        marginTop: '5px',
        marginBottom: '10px',
    }
}));

const Basic = ({setBasic, values, originalData}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const [state, setState] = useState({
        title: values.title,
        slug: values.slug,
        description: values.description
    });

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    useDebouncedEffect(() => {
        console.log("Basic state", state);
        setBasic({
            completed: isCompleted(),
            values: state,
        });
    }, [state], 200);

    const isCompleted = () => {
        let empty = false;
        for(const field of fields){
            if(state[field.name] === undefined || state[field.name] === null || state[field.name] === "") {
                console.debug(field.name, "not completed");
                empty = true;
                break;
            }
            else {
                console.debug(field.name, "is completed");
            }
        }

        return !empty;
    };

    const fields = [
        {
            label: t('pages:event:information:basic.form.title'),
            name: "title",
        },
        {
            label: t('pages:event:information:basic.form.description'),
            name: "description",
            help: t('pages:event:information:basic.form.description-help'),
            multiline: true,
        }
    ]

    return (
        <div>
            <Typography variant={"h4"} className={classes.title}>{t('pages:event:information:basic.title')}</Typography>
            <Divider className={classes.divider} />
            {fields.map((field, index) => {
                return (
                    <React.Fragment key={index}>
                        <TextField
                            name={field.name}
                            label={field.label}
                            fullWidth
                            size={"small"}
                            onChange={handleChange}
                            className={classes.fieldInput}
                            multiline={field.multiline ? field.multiline : false}
                            value={state[field.name] ?? ""}
                            rows={7}
                            InputProps={{
                                startAdornment: field.name === "slug" ? <InputAdornment position="start">{t('pages:event:information:basic.form.slug-adornment')}</InputAdornment> : <></>,
                            }}
                        />
                        {field.help && <FormHelperText>{field.help}</FormHelperText>}
                    </React.Fragment>
                )
            })}
        </div>
    );
}

export default Basic;
