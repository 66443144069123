import React, {useState} from 'react';
import Template from "../../Base/Template";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {Link as RouterLink, Redirect} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import {useSnackbar} from "notistack";
import Api from "../../../utils/Api";
import {getTenant, getToken} from "../../../helper/AuthHelper";
import LanguageSelect from "../../../components/LanguageSelect";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    field: {
        marginBottom: '20px'
    },
    backButton: {
        marginBottom: theme.spacing(2),
    },
    button: {
        marginBottom: theme.spacing(2),
        maxWidth: '300px'
    }
}));

const EmailCreate = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const [state, setState] = useState({
        type: "",
        subject: "",
        content_html: "",
        content_text: "",
        language: "",
        active: false,
    });
    const [created, setCreated] = useState(false);

    const handleChange = (e) => {
      setState({
          ...state,
          [e.target.name]: e.target.value,
      });
    };
    const handleCheckboxChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.checked,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const tenantId = getTenant().id;

        try {
            const response = await Api.post(`/v2/tenants/${tenantId}/email_templates`, state, {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            });

            if(response.status === 201){
                enqueueSnackbar(t('pages:settings:email:create:notifier-success'), {variant: "success"});
                setCreated(true);
            }
        }
        catch(e) {
            enqueueSnackbar(t('pages:settings:email:create:notifier-failure'), {variant: "error"});
        }
    }

    if(created === true){
        return <Redirect to={"/settings/email-templates"} />;
    }

    return (
        <Template title={t('pages:settings:email:create:title')}>
            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    size={"small"}
                    className={classes.backButton}
                    startIcon={<ArrowBackIcon />}
                    component={RouterLink}
                    to={"/settings/email-templates"}
                >
                    {t('globals:buttons:backtooverview')}
                </Button>
                <Paper className={classes.paper}>
                    <Typography variant={"h4"} style={{marginBottom: '20px'}}>{t('pages:settings:email:create:heading')}</Typography>
                    <FormControl className={classes.field}>
                        <InputLabel id="email-type-label">{t('pages:settings:email:form.type')}</InputLabel>
                        <Select
                            labelId="email-type-label"
                            name={"type"}
                            value={state.type}
                            onChange={handleChange}
                        >
                            <MenuItem value={"user_verification"}>{t('pages:settings:email:form.type-userverification')}</MenuItem>
                            <MenuItem value={"event_registration"}>{t('pages:settings:email:form.type-eventregistration')}</MenuItem>
                            <MenuItem value={"event_reminder"}>{t('pages:settings:email:form.type-eventreminder')}</MenuItem>
                            <MenuItem value={"user_password_reset"}>{t('pages:settings:email:form.type-passwordreset')}</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        className={classes.field}
                        label={t('pages:settings:email:form.subject')}
                        name={"subject"}
                        value={state.subject}
                        onChange={handleChange}
                    />
                    <LanguageSelect className={classes.field} value={state.language} onChange={handleChange} labelTranslationKey={"pages:settings:email:form.language"} />
                    <TextField
                        className={classes.field}
                        label={t('pages:settings:email:form.content_html')}
                        name={"content_html"}
                        multiline
                        rows={10}
                        value={state.content_html}
                        onChange={handleChange}
                    />
                    <TextField
                        className={classes.field}
                        label={t('pages:settings:email:form.content_text')}
                        name={"content_text"}
                        multiline
                        rows={10}
                        value={state.content_text}
                        onChange={handleChange}
                    />
                    <FormControlLabel
                        className={classes.field}
                        control={
                            <Checkbox
                                name={"active"}
                                color="primary"
                                value={state.active}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label={t('pages:settings:email:form.active')}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                        onClick={handleSubmit}
                    >
                        {t('globals:buttons:create')}
                    </Button>
                </Paper>
            </Grid>
        </Template>
    );
};

export default EmailCreate;
