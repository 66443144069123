import API from "../../utils/Api";
import {getToken} from "../../helper/AuthHelper";
import {
    FETCH_EVENT,
    FETCH_EVENT_FAILURE,
    FETCH_EVENT_INGEST, FETCH_EVENT_INGEST_FAILURE,
    FETCH_EVENT_INGEST_SUCCESS, FETCH_EVENT_INITIAL,
    FETCH_EVENT_SUCCESS
} from "../types/singleEventTypes";

export const fetchSingleEvent = (id, initial = false) => {

    return (dispatch) => {
        if(initial) {
            dispatch(fetchSingleEventsStartInitial());
        }
        else {
            dispatch(fetchSingleEventsStart());
        }
        API.get(`/v2/events/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
            .then((response) => {
                dispatch(fetchSingleEventSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchSingleEventsFailure(error));
            })
    }
}

export const fetchSingleEventSuccess = (data) => {
    return {
        type: FETCH_EVENT_SUCCESS,
        event: data.event,
    }
};

export const fetchSingleEventsStart = () => {
    return {
        type: FETCH_EVENT
    };
}
export const fetchSingleEventsStartInitial = () => {
    return {
        type: FETCH_EVENT_INITIAL
    };
}

export const fetchSingleEventsFailure = (error) => {
    return {
        type: FETCH_EVENT_FAILURE,
        errMsg: error.message,
        errCode: error.response ? error.response.status ?? -1 : -1,
    }
}


export const fetchSingleEventIngest = (id) => {

    return (dispatch) => {
        dispatch(fetchSingleEventIngestStart());
        API.get(`/v2/events/${id}/ingest`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
          .then((response) => {
              dispatch(fetchSingleEventIngestSuccess(response.data));
          })
          .catch((error) => {
              dispatch(fetchSingleEventIngestFailure(error));
          })
    }
}

export const fetchSingleEventIngestSuccess = (data) => {
    return {
        type: FETCH_EVENT_INGEST_SUCCESS,
        ingest: data.ingest,
    }
};

export const fetchSingleEventIngestStart = () => {
    return {
        type: FETCH_EVENT_INGEST
    };
}

export const fetchSingleEventIngestFailure = (error) => {
    return {
        type: FETCH_EVENT_INGEST_FAILURE,
        errMsg: error.message,
        errCode: error.response ? error.response.status ?? -1 : -1,
    }
}
