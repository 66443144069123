import API from "../../utils/Api";

import {getToken} from "../../helper/AuthHelper";
import {FETCH_SINGLE_USER, FETCH_SINGLE_USER_FAILURE, FETCH_SINGLE_USER_SUCCESS} from "../types/singleUserTypes";

export const fetchSingleUser = (id) => {

    return (dispatch) => {
        dispatch(fetchSingleUserStart());
        API.get(`/v2/users/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
            .then((response) => {
                dispatch(fetchSingleUserSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchSingleUserFailure(error));
            })
    }
}

export const fetchSingleUserStart = () => {
    return {
        type: FETCH_SINGLE_USER
    };
}


export const fetchSingleUserSuccess = (data) => {
    return {
        type: FETCH_SINGLE_USER_SUCCESS,
        user: data.user,
    }
};

export const fetchSingleUserFailure = (error) => {
    return {
        type: FETCH_SINGLE_USER_FAILURE,
        errMsg: error.message,
        errCode: error.response ? error.response.status ?? -1 : -1,
    }
}

