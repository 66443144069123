import React, {useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import {Trans, useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import Basic from "./Basic";
import Time from "./Time";
import External from "./External";
import Meta from "./Meta";
import {Alert, AlertTitle} from "@material-ui/lab";

const useStyles = makeStyles(() => ({
    informationContainer: {
        marginBottom: '20px'
    },
    descriptionContainer: {
        marginBottom: '30px',
    }
}));

const Information = ({stepId, values, eventType, handleCallback}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [basic, setBasic] = useState({
        completed: false,
        values: {},
    });

    const [external, setExternal] = useState({
        completed: false,
        values: {},
    });

    const [time, setTime] = useState({
        completed: false,
        values: {}
    });

    const [meta, setMeta] = useState({
        completed: false,
        values: {}
    });

    useEffect(() => {
        let completed = false;
        if(basic.completed) {
            if(eventType === "external" && external.completed && time.completed) {
                completed = true;
            }
            else if(eventType === "vod") {
                completed = true;
            }
            else {
                completed = true;
            }
        }
        handleCallback(stepId, completed, {
            ...basic.values,
            ...external.values,
            ...time.values,
            ...meta.values,
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basic, external, time, meta]);

    return (
        <div>
            <Typography variant={"subtitle1"} component={"p"} className={classes.descriptionContainer} >
                <Trans i18nKey={"pages:event:create:information:description"}/>
            </Typography>
            <div className={classes.informationContainer}>
                <Basic setBasic={setBasic} values={values} />
                {eventType === "external" &&
                    <External setExternal={setExternal} values={values} />
                }
                {eventType !== "vod" &&
                  <Time setTime={setTime} values={values} />
                }
                <Meta setMeta={setMeta} values={values} />
            </div>
            {eventType === "vod" &&
              <Alert severity={"info"} style={{marginTop: 30}}>
                  <AlertTitle style={{fontWeight: 700}}>{t('pages:event:create:information:vodInfo:title')}</AlertTitle>
                  <Typography>{t('pages:event:create:information:vodInfo:description')}</Typography>
              </Alert>
            }
        </div>
    )
};

export default Information;
