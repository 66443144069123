import {createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import rootReducer from './reducers';
import {composeWithDevTools} from "redux-devtools-extension";
import throttle from 'lodash.throttle';


const storageName = 'streamingapp_admin_state';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(storageName);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(storageName, serializedState);
  } catch {
    // ignore write errors
  }
};

export const clearState = () => {
  localStorage.removeItem(storageName);
};

const store = createStore(rootReducer,
  loadState(), composeWithDevTools(
  applyMiddleware(thunk),
));

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

export {store};
