import React, {useState} from "react";
import {Box, Button, Divider, LinearProgress, Typography} from "@material-ui/core";
import {Trans, useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {CloudUpload} from "@material-ui/icons";
import {uploadSvcClient} from "../../../../../utils/Api";
import {Alert} from "@material-ui/lab";
import {connect} from "react-redux";


const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" {...props} style={{height: '10px'}} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                props.value,
              )}%`}</Typography>
          </Box>
      </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main
    },
    fieldInput: {
        marginTop: '10px'
    },
    divider: {
        marginTop: '5px',
        marginBottom: '10px',
    }
}));

const Upload = ({event}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const CHUNK_SIZE = 10 * 1024 * 1024;

    const [percentage, setPercentage] = useState(0.00);
    const [state, setState] = useState("PENDING");

    let file = null;
    let fileSize = 0;
    let currentChunk = 0;
    let currentChunkStartByte = 0;
    let uploadSession = null;

    const handleFileUpload = async (e) => {
        if (!e.target.files) {
            console.log("no files");
            return;
        }
        file = e.target.files[0];
        fileSize = file.size;

        await createSession();

        handleUpload();
    };

    const handleUpload = async () => {
        if(currentChunkStartByte < fileSize) {
            currentChunk++;

            const chunkEnd = Math.min(currentChunkStartByte + CHUNK_SIZE, fileSize);
            const chunk = file.slice(currentChunkStartByte, chunkEnd);

            if(currentChunkStartByte + CHUNK_SIZE < fileSize) {
                currentChunkStartByte += CHUNK_SIZE;
            }
            else {
                currentChunkStartByte = fileSize;
            }

            await uploadChunk(chunk);

            const fractionCompleted = chunkEnd / fileSize;
            setPercentage(fractionCompleted * 100);

        }
        else {
            await finishSession();
            setPercentage(100);
        }
    }

    async function createSession() {
        setPercentage(0);
        if(!event.event?.video_id) {
            setState("FAILED");
            return;
        }
        const response = await uploadSvcClient.post("/upload/start", {
            video_id: event.event?.video_id,
        });
        uploadSession = response.data.access_token;
        setState("UPLOADING");
    }

    async function finishSession() {
        try {
            await uploadSvcClient.post("/upload/finish", {}, {
                headers: {
                    Authorization: `Bearer ${uploadSession}`
                }
            });
            setState("SUCCESS");
        }
        catch (e){
            console.error(e.message);
            setState("FAILED");
        }
    }

    async function uploadChunk(chunk) {

        const contentRange = "bytes " + currentChunkStartByte + "-" + (currentChunkStartByte+CHUNK_SIZE-1) + "/" + file.size;
        try {
            const chunkForm = new FormData();
            chunkForm.append('chunk', chunk);
            await uploadSvcClient.put("/upload/chunk", chunkForm, {
                headers: {
                    "Content-Range": contentRange,
                    "X-Part-Number": currentChunk,
                    Authorization: `Bearer ${uploadSession}`,
                },
            });
            handleUpload();

        } catch (e) {
            console.error(e.message);
            setState("FAILED");
        }

    }

    return (
        <div style={{marginTop: '50px'}}>
            <Typography variant={"h4"} className={classes.title}>{t('pages:event:edit:information:upload.title')}</Typography>
            <Divider className={classes.divider} />
            {event.event.media_data?.state === "ready" &&
              <div>
                  <Alert severity={"success"}>
                      {t('pages:event:edit:information:upload:alert-current-video-ready')}
                  </Alert>
                  <div>
                      <Typography variant={"subtitle2"} style={{marginBottom: '5px'}}>{t('pages:event:edit:information:upload:thumbnail-image')}</Typography>
                      <img src={event.event.media_data?.thumbnail_image} style={{maxWidth: '250px'}} alt={"Thumbnail"} />
                  </div>
              </div>
            }
            {event.event.media_data?.state === "transcode_pending" &&
              <Alert severity={"info"}>
                  {t('pages:event:edit:information:upload:alert-current-video-transcoding')}
              </Alert>
            }
            {(event.event.media_data?.state === "upload_pending" || event.event.media_data?.state === "transcode_failed") &&
              <>
                  {event.event.media_data?.state === "transcode_failed" &&
                    <Alert severity={"warning"} style={{marginBottom: '15px'}}>
                        {t('pages:event:edit:information:upload:alert-current-video-failed')}
                    </Alert>
                  }
                  <Typography variant={"subtitle2"} style={{marginBottom: '15px'}}>
                      <Trans i18nKey={'pages:event:edit:information:upload:description'} />
                  </Typography>
                  <Button
                    component="label"
                    variant="contained"
                    size={"small"}
                    startIcon={<CloudUpload />}
                    sx={{ marginRight: "1rem" }}
                    disabled={state === "UPLOADING" || state === "SUCCESS"}
                  >
                      {t('pages:event:edit:information:upload:upload-button')}
                      <input type="file" accept={"video/mp4"} hidden onChange={handleFileUpload} />
                  </Button>
                  {state === "UPLOADING" &&
                    <LinearProgressWithLabel value={percentage} />
                  }
                  {state === "SUCCESS" &&
                    <Alert severity={"success"} style={{marginTop: '15px'}}>
                        <Trans i18nKey={'pages:event:edit:information:upload:alert-success'} />
                    </Alert>
                  }
                  {state === "FAILURE" &&
                    <Alert severity={"error"} style={{marginTop: '15px'}}>
                        <Trans i18nKey={'pages:event:edit:information:upload:alert-failure'}/>
                    </Alert>
                  }
              </>
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        event: state.singleEvent,
    };
}


export default connect(mapStateToProps, null)(Upload);
