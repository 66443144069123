export const APP_LANGUAGE_EN = {
    app: {
        "htmltitle.short": "Streamingapp Admin",
        "htmltitle.long": "%s - Streamingapp Admin",
        "lngswitcher.title": "Change language",
        "lngswitcher.german": "German",
        "lngswitcher.english": "English",
    },
    nav: {
        "dashboard": "Dashboard",
        "events": "Events",
        "users": "Users",
        "admin_users": "Admin users",
        "settings": "Settings",
        "settings.email": "Emails",
        "settings.tenant": "Tenant profile",
        "logout": "Sign-out",
    },
    globals: {
        languages: {
            "german": "German",
            "english": "English",
            "french": "French",
            "italian": "Italian",
            "polish": "Polish",
            "russian": "Russian",
            "spanish": "Spanish",
            "turkish": "Turkish",
            "de": "German",
            "en": "English",
            "fr": "French",
            "it": "Italian",
            "pl": "Polish",
            "ru": "Russian",
            "es": "Spanish",
            "tr": "Turkish",
        },
        buttons: {
            "new": "New",
            "cancel": "Cancel",
            "close": "Close",
            "backtooverview": "Back to overview",
            "save-update": "Save changes",
            "create": "Create",
            "delete": "Delete",
            "delete-forever": "Delete irreversibly"
        }
    },
    pages: {
        dashboard: {
            "title": "Dashboard",
            "heading": "Welcome to the administration area.",
            "description": "Please select the desired action from the menu on the left."
        },
        login: {
            "title": "Login",
            "subtitle": "Please sign-in to use the app.",
            "form.email": "Email address",
            "form.password": "Password",
            "form.tenant": "Tenant",
            "form.submit":"Sign in",
            "form.submittenant": "Choose tenant and sign-in",
            "button-sso-login": "Continue with JOY Single-Sign-On",
            "tenanthelptext": "There are multiple tenants assigned to your account. Which one would you like to sign-in for?",
            "legallinks": "Imprint / Privacy Policy",
            "alert.failure.title": "Sign-in failed",
            "alert.failure.message": "Please check your credentials and try again.",
            "alert.loggedin.title": "You are already signed-in.",
            "alert.loggedin.message": "Continue to the app dashboard."
        },
        user: {
            "form.first_name": "First name",
            "form.last_name": "Last name",
            "form.language": "Language",
            "form.company_name": "Company name",
            "form.postalcode": "Postal code",
            "form.city": "City",
            "form.active": "User is activated",
            "form.email": "Email address",
            "form.active_not_allowed_message": "For privacy reasons, you are not allowed to change the user's active status.",
            list: {
                "title": "User overview",
                "table.heading":"Registered users",
                "table.email": "email",
                "table.firstname": "first name",
                "table.lastname": "last name",
                "table.state": "state",
                "table.state-active": "Active",
                "table.state-inactive": "Inactive",
                "table.language": "Language",
                "export-btn": "Export users",
                export: {
                    "dialog-title": "Export user data",
                    "dialog-description": "Before starting the export, please make sure that you will only use the data exported here for privacy-compliant purposes.<br />User data may only be passed on to authorized persons.",
                    "dialog-export-button": "Export user data now"
                }
            },
            create: {
                "title": "Create new user",
                "notifier-success": "The user was successfully created.",
                "notifier-failure": "The user could not be created, please check the entries.",
            },
            edit: {
                "title": "Update user",
                "form.email-help": "This value cannot be changed.",
                "dialog.delete-title": "Delete user?",
                "dialog.delete-description": "If you delete the user, all data associated with him will be irrevocably deleted. This includes, among other things, event registrations and entries in interactive features.<br /><br /><strong>Notice:</strong><br />After deletion, the user can create a new account.",
                "notifier-delete-success": "The user was successfully deleted.",
                "notifier-delete-failure": "The user could not be deleted.",
                "notifier-failure": "The user could not be updated.",
                "notifier-success": "The user was successfully updated.",
            }
        },
        admin_user: {
            "form.language": "User language",
            "form.active": "User is activated",
            "form.email": "Email address",
            "form.first_name": "First name",
            "form.last_name": "Last name",
            "form.manageable_languages": "Manageable languages",
            "form.company_admin": "Company administrator",
            "form.company_admin_helper": "Company administrators have advanced permissions such as managing email templates or other administrators.",
            create: {
                "title": "Create new administrator",
                "notifier-success": "The user was created successfully.",
                "notifier-failure": "The user could not be created, please check the entries.",
            },
            list: {
                "title": "Admin-user overview",
                "table.heading": "Registered administrators",
                "table.email": "Email address",
                "table.firstname": "First name",
                "table.lastname": "Last name",
                "table.state": "State",
                "table.state-active": "Active",
                "table.state-inactive": "Inactive",
                "table.manageable_languages": "Manageable langauges",
            },
            edit: {
                "title": "Update admin user",
                "form.email-help": "This value cannot be changed.",
                "dialog.delete-title": "Delete user?",
                "dialog.delete-description": "If you delete the user, all data associated with him will be irrevocably deleted. This includes, among other things, event registrations and entries in interactive features.<br /><br /><strong>Notice:</strong><br />After deletion, the user can create a new account.",
                "notifier-delete-success": "The user was successfully deleted.",
                "notifier-delete-failure": "The user could not be deleted.",
                "notifier-failure": "The user could not be updated.",
                "notifier-success": "The user was successfully updated.",
            }
        },
        event: {
            "authtype.public": "Public",
            "authtype.shared_secret": "Shared secret",
            "authtype.user": "Individual user",
            "button.back": "Back to overview",
            "button.stepback": "Back",
            "button.continue": "Continue",
            "info.stepback": "Previously entered data could be lost if you go back.",
            information: {
                basic: {
                    "title": "Basic data",
                    "form.title": "Title",
                    "form.slug": "Link extension",
                    "form.slug-adornment": "https://[ihre-domain.de]/event/",
                    "form.slug-help": "Example: \"demoevent\" becomes: https://[ihre-domain.de]/event/demoevent",
                    "form.description": "Description text",
                    "form.description-help": "The description text is displayed on the event page. Here you can insert, for example, a content description or agenda.",
                    "form.slugtaken": "This link extension is already taken."
                },
            },
            list: {
                "title": "Event overview",
                "table.heading": "Created Events",
                "table.title": "Title",
                "table.start_date": "Start date",
                "table.type": "Type",
                "table.auth_type": "Access option",
                "table.language": "Language",
                "type.live": "Live event",
                "type.external": "External event",
                "type.vod": "Video on demand",
                "authtype.public": "Public",
                "authtype.shared_secret": "Shared secret",
                "button.new": "New"
            },
            create: {
                "title": "Create new event",
                "button.back": "Back to overview",
                "button.stepback": "Back",
                "button.continue": "Continue",
                "button.create": "Create event",
                "steps.metadata": "Information about your event",
                "steps.type": "Event type",
                "steps.pricing": "Pricing information",
                "steps.viewers": "Viewers",
                "steps.summary": "Summary",
                "info.stepback": "Previously entered data could be lost if you go back.",
                type: {
                    "description": "<strong>Welcome to our event editor!</strong><br />As a first step, please choose which kind of event you would like to create.",
                    "live.title": "Live event",
                    "live.description": "Broadcast your event as a livestream to your viewers.",
                    "vod.title": "Video on demand",
                    "vod.description": "Make a video created in advance available for viewing.",
                    "external.title": "External event",
                    "external.description": "Use our platform to link to an external event (e.g. a video conference in Microsoft Teams, Zoom, or similar).",
                },
                information: {
                    "description": "Enter the relevant information about the event here.<br />This can of course also be adjusted after the event has been created.",
                    vodInfo: {
                        "title": "Information for VOD content",
                        "description": "You can upload the video file after event creation."
                    },
                    basic: {
                        "title": "Basic data",
                        "form.title": "Title",
                        "form.slug": "Link extension",
                        "form.slug-adornment": "https://[your-domain.com]/event/",
                        "form.slug-help": "Example: \"demoevent\" becomes: https://[your-domain.com]/event/demoevent",
                        "form.description": "Description text",
                        "form.description-help": "The description text is displayed on the event page. Here you can insert, for example, a content description or agenda."
                    },
                    time: {
                        "title": "Schedule",
                        "notice-timezone": "Note: The times entered here are based on your browser timezone ({{timezone}}).",
                        "form.start_date": "Start time",
                        "form.end_date": "Planned end date",
                        "form.end_date_hidden": "The end time should not be displayed",
                        "error.end_before_start": "The end time must not be less than or equal to the start time.",
                    },
                    external: {
                        "title": "Link to the external event",
                        "description": "You have selected that you want to create an external event. Please enter here the link to which you want to refer your viewers.",
                        "form.link": "Link"
                    },
                    meta: {
                        "title": "Filter options",
                        "form.language": "Language",
                        "form.language-help": "Select here in which language this event will be held.",
                        "form.tags": "Tags / Keywords",
                        "form.tags-help": "You can enter multiple keywords by pressing the Enter / \"comma\" or space key."
                    },
                    protection: {
                        "title": "Access restriction",
                        "description": "Here you can select who should have access to your event.",
                        "auth_type": "Type of access restriction",
                        "shared_secret": "Enter password...",
                        "shared-secret-help": "What password should users use to register for the event?"
                    },
                },
                summary: {
                    "description": "Thank you, that's almost it!<br />Please click \"Create Event\" to complete the creation.",
                    "creationerror": "An error occurred while creating the event."
                }
            },
            edit: {
                "title": "Edit event",
                "button.registrations": "Manage registrations",
                "button.ingest-data": "Live-ingest data",
                "button.questionbox-data": "Question box",
                "notifier-loading-error": "The event could not be loaded.",
                "notifier-error": "The event could not be updated.",
                "notifier-success": "The event was updated successfully.",
                "notifier-delete-success": "The event was successfully updated.",
                "notifier-delete-failure": "The event could not be deleted.",
                "dialog.delete-title": "Delete event?",
                "dialog.delete-description": "If you delete the event, all associated data will be irrevocably deleted. This includes but is not limited to event registrations.<br /><br /><strong>Notice:</strong><br />After deletion a new event with the same link extension can be created.",
                "dialog.ingest-title": "Live ingest informationen",
                "dialog.ingest-description": "Use this information to start broadcasting to this event.<br /><strong>Share this information only with authorized persons.</strong>",
                "dialog.ingest-available-servers": "Available ingest points",
                "dialog.ingest-choose-server": "Choose ingest point above",
                "dialog.ingest-stream-server": "Stream server",
                "dialog.ingest-stream-key": "Stream key",
                "dialog.questionbox-title": "Questionbox links",
                "dialog.questionbox-description": "Use the following links to give access to the question box.<br /><strong>Share this information only with authorized persons.</strong>",
                "dialog.questionbox-moderator": "Panel link for moderator / host (only approved questions)",
                "dialog.questionbox-editor": "Panel link for editor (approved and pending questions)",
                information: {
                    "description": "Here you can update the relevant information of the event.",
                    upload: {
                        "title": "File upload",
                        "description": "You can upload the video file for this event here.<br /><br />We accept the following formats:<br />- .mp4 (Video codecs: H264, Audio codecs: AAC, MP3)<br /><br />If you upload the video in another format the upload or processing fails.",
                        "upload-button": "Choose file",
                        "alert-success": "The file was uploaded successfully and will be processed now.<br /><strong>This process can take a few hours, depending on video length.</strong>",
                        "alert-failure": "There was an error while uploading your file. Please reload the page and try again.<br />If this error persists please contact support.",
                        "alert-current-video-transcoding": "Your uploaded video is processing right now. This process can take a few hours, depending on video length.",
                        "alert-current-video-failed": "Your last uploaded video could not be processed. Please check the video format and try again.",
                        "alert-current-video-ready": "The video was processed successfully and is ready for playback.",
                        "thumbnail-image": "Thumbnail image",
                    },
                },
                summary: {
                    "description": "Thank you very much, that was almost it!<br />By clicking on \"Update\", the changes are saved.",
                },
            },
            stats: {
                "title": "Event stats",
                livestats: {
                    cards: {
                        "live_viewers": "Active viewers"
                    },
                    chips: {
                        "live": "Live",
                        "notlive": "Planned"
                    },
                    viewers: {
                        "table-heading": "Active viewers",
                        "first_name": "First name",
                        "last_name": "Last name",
                        "company_name": "Company"
                    },
                    "title-notlive": "This event hasn't started yet.",
                    "message-notlive": "Once this event has started you can view the live stats here.",
                    "title": "Live stats",
                    "table-title": "Time history",
                    "last-update": "Last update:"
                },
                archive: {
                    viewers: {
                        "table-heading": "Viewer history",
                        "first_name": "First name",
                        "last_name": "Last name",
                        "company_name": "Company",
                        "watch_time": "Watch time (hh:mm:ss)"
                    },
                },
            }
        },
        settings: {
            "title": "Company settings",
            "description": "Here you can customize the settings of your company.",
            "form.email_from": "Email sender name",
            "form.email_smtpserver": "SMTP server",
            "form.email_smtpusername": "SMTP username",
            "form.email_smtppassword": "SMTP password",
            "form.email_smtpport": "SMTP port",
            "form.email_smtpencryption": "SMTP encryption",
            "form.email_smtpauthmode": "SMTP authentication",
            "form.email_verification_urltemplate": "Template for verification link",
            "form.email_verification_urltemplate_help": "Required parts: User-ID: %USERID% - Verify token: %TOKEN% - User language: %USERLANGUAGE%",
            "form.email_passwordreset_urltemplate": "Template for password reset link",
            "form.email_passwordreset_urltemplate_help": "Required parts: User-ID: %USERID% - Verify token: %TOKEN%",
            "form.email_transportenabled": "Enable sending via own SMTP server.",
            "button.save": "Save changes",
            "button.mailtest": "Test mail delivery",
            "notifier-success": "The changes have been saved successfully..",
            "notifier-error": "The changes could not be saved.",
            "notifier-configerror": "The changes could not be saved because the validation failed.",
            "notifier-loading-error": "The data could not be loaded.",
            "notifier-testmail-success": "The test email was sent successfully.",
            "notifier-testmail-error": "The test email could not be sent. Please check the settings.",
            "notifier-testmail-configerror": "The test cannot be performed because you have not enabled shipping via your own server.",
            email: {
                "form.type": "Email type",
                "form.subject": "Subject",
                "form.content_html": "HTML content",
                "form.content_text": "Text content",
                "form.language": "Language",
                "form.active": "Active",
                "form.type-userverification": "Account verification",
                "form.type-eventregistration": "Confirmation of event registration",
                "form.type-eventreminder": "Event reminder for registered user",
                "form.type-passwordreset": "Reset password",
                list: {
                    "title": "Email templates",
                    "table.heading": "Created email templates",
                    "table.id": "Internal ID",
                    "table.type": "Type",
                    "table.language": "Language",
                    "table.state": "State",
                    "table.state-active": "Active",
                    "table.state-inactive": "Inactive",
                    "type.event_registration": "Confirmation of event registration",
                    "type.event_reminder": "Event reminder",
                    "type.user_verification": "Account verification",
                    "type.user_password_reset": "Reset password",
                    "button.new": "New templates",
                    "confirm.title": "Delete email tempalte?",
                    "confirm.description": "Are you sure you want to delete the email template?",
                    "notifier.delete-success": "The template was successfully deleted.",
                    "notifier.delete-failure": "The template could not be deleted.",
                },
                create: {
                    "title": "Create email template",
                    "heading": "New email template",
                    "notifier-success": "The template was successfully created.",
                    "notifier-failure": "The template could not be created."
                },
                edit: {
                    "title": "Update email template",
                    "heading": "Edit template",
                    "notifier.success": "The template was successfully updated.",
                    "notifier.failure": "The template could not be updated.",
                    "notifier.loading-failure": "The e-mail template could not be loaded.",
                }
            }
        }
    },
    components: {
        dataTable: {
            "rowsPerPage": "Entries per page",
            "rangeSeparator": "of",
            "noData": "There are no entries.",
            "switchDenseLayout": "Compact layout",
            "searchBox": "Search...",
            "searchActive": "Search filter enabled"
        }
    }
};
