import React, {useEffect, useState} from "react";
import logo from "../../assets/logo.svg";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import {Collapse} from "@material-ui/core";
import {getToken, removeToken, useAuth} from "../../helper/AuthHelper";
import Api from "../../utils/Api";
import {Link as RouterLink, useLocation} from "react-router-dom";

/* Icons */
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LockIcon from '@material-ui/icons/Lock';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SettingsIcon from '@material-ui/icons/Settings';
import EmailIcon from '@material-ui/icons/Email';
import BusinessIcon from '@material-ui/icons/Business';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

/* Translation */
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from "./LanguageSwitcher";
import axios from "axios";
import {login, loginReset} from "../../store/actions/loginActions";
import {connect} from "react-redux";
import {clearState} from "../../store";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    logo: {
        maxHeight: '60px',
        padding: '10px 0 10px 0',
        margin: '0 auto',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    active: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    versionInfo: {
        borderTop: '1px solid #ccc',
        padding: '15px',
        fontSize: '9px',
        color: '#a7a7a7',
    }
}));

const NavItems = (props) => {

    const {check} = useAuth();

    const logout = async () => {
        try {
            await Api.get("/v2/auth/logout", {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            });
            removeToken();
            clearState();

            check();
            window.location.href = "/login";
        }
        catch(e) {
            console.error(e);
            window.location.href = "/login";
        }
    }

    /* Load version information file */
    const [versionInfo, setVersionInfo] = useState({
        tag: 'dev-master',
        sha: '00000000',
        buildtime: new Date().toISOString()
    });
    const loadVersionInfo = async () => {
        try{
            const response = await axios.get("/version.json");

            setVersionInfo(response.data);
        }
        catch(e){
            console.debug("Version information could not be fetched.", e);
            return false;
        }
    };
    useEffect(() => {
        loadVersionInfo();
    }, []);

    const [openSettingsNav, setOpenSettingsNav] = React.useState(false);

    const classes = useStyles();
    const { t } = useTranslation();

    const {pathname} = useLocation();

    return (
        <div>
            <div className={classes.toolbar}>
                <img alt="Logo" src={logo} className={classes.logo}/>
            </div>
            <Divider/>
            <List>
                <ListItem className={pathname === "/" ? classes.active : null} button component={RouterLink} to={"/"}>
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('nav:dashboard')}
                    />
                </ListItem>
                <ListItem className={pathname.includes("/events") ? classes.active : null} button component={RouterLink} to={"/events"}>
                    <ListItemIcon>
                        <EventNoteIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('nav:events')}
                    />
                </ListItem>
                <ListItem className={pathname.includes("/users") ? classes.active : null} button component={RouterLink} to={"/users"}>
                    <ListItemIcon>
                        <PeopleAltIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('nav:users')}
                    />
                </ListItem>
                {props.loginState.user.roles.includes("ROLE_COMPANY_ADMIN") &&
                  <>
                      <ListItem className={pathname.includes("/admin_users") ? classes.active : null} button component={RouterLink} to={"/admin_users"}>
                          <ListItemIcon>
                              <VerifiedUserIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('nav:admin_users')}
                          />
                      </ListItem>
                      <ListItem className={pathname.includes("/settings") ? classes.active : null} onClick={() => {
                          setOpenSettingsNav(!openSettingsNav);
                      }}>
                          <ListItemIcon>
                              <SettingsIcon/>
                          </ListItemIcon>
                          <ListItemText
                            primary={t('nav:settings')}
                          />
                          {openSettingsNav ? <ExpandLess/> : <ExpandMore/>}
                      </ListItem>
                      <Collapse in={openSettingsNav} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                              <ListItem button component={RouterLink} to={"/settings/email-templates"} className={classes.nested}>
                                  <ListItemIcon>
                                      <EmailIcon />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={t('nav:settings.email')}
                                  />
                              </ListItem>
                              <ListItem button component={RouterLink} to={"/settings/tenant"} className={classes.nested}>
                                  <ListItemIcon>
                                      <BusinessIcon />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={t('nav:settings.tenant')}
                                  />
                              </ListItem>
                          </List>
                      </Collapse>
                  </>
                }
            </List>

            <Divider/>

            <List>
                <ListItem>
                    <LanguageSwitcher />
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                        <LockIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('nav:logout')}
                        onClick={logout}
                    />
                </ListItem>
            </List>

            <div className={classes.versionInfo}>
                version {versionInfo?.tag} (#{versionInfo.sha.substring(0,7)})<br />
                built at {versionInfo?.buildtime}
            </div>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        loginState: state.login,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (email, password, tenant_id) => dispatch(login(email, password, tenant_id)),
        reset: () => dispatch(loginReset()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavItems);
