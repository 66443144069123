import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {connect} from "react-redux";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DataTable from "../../../components/DataTable";
import Api from "../../../utils/Api";
import {getToken} from "../../../helper/AuthHelper";
import {useSnackbar} from "notistack";
import {fetchEventRegistrations} from "../../../store/actions/eventRegistrationsActions";

const List = (props) => {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const columns = [
        {
            selector: row => row['first_name'],
            name: t('pages:event:registrations:table.first_name'),
            sortable: true
        },
        {
            selector: row => row['last_name'],
            name: t('pages:event:registrations:table.last_name'),
            sortable: true
        },
        {
            selector: row => row['company_name'],
            name: t('pages:event:registrations:table.company_name'),
            sortable: true
        },
        {
            selector: row => row['email'],
            name: t('pages:event:registrations:table.email'),
            sortable: true
        },
        {
            name: t('pages:event:registrations:table.language'),
            sortable: false,
            cell: ({language}) => {
                return t(`globals:languages:${language}`);
            }
        },
        {
            cell: ({id}) => {
                return <><RemoveCircleIcon onClick={() => {handleDeleteOpen(id);}} color={"secondary"}/></>
            },
            button: true,
        },
    ];

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const handleDeleteOpen = (id) => {
        setDeleteId(id);
        setDeleteOpen(true);
    }
    const handleDeleteClose = () => {
        setDeleteId(null);
        setDeleteOpen(false);
    }
    const handleDeletion = async () => {
        try {
            const response = await Api.post(`/v2/events/${props.eventId}/unregister`, {
                user_id: deleteId
            }, {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            });
            if(response.status === 200){
                enqueueSnackbar(t('pages:event:registrations:notifier-delete-success'),{variant: "success"});
                handleDeleteClose();
                props.fetchRegistrations(props.eventId);
            }
        }
        catch(e) {
            console.debug("Deletion of registration failed.", e);
            enqueueSnackbar(t('pages:event:registrations:notifier-delete-failure'),{variant: "warning"});
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <DataTable
                title={t('pages:event:registrations:table.heading')}
                columns={columns}
                data={props.registrations.registrations}
                denseLayout={true}
            />
            <Dialog
                open={deleteOpen}
                onClose={handleDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{t('pages:event:registrations:dialog.title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText><Trans i18nKey={'pages:event:registrations:dialog.description'} /></DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} variant={"outlined"}>
                        {t('globals:buttons:cancel')}
                    </Button>
                    <Button onClick={handleDeletion} color="primary" variant={"contained"} autoFocus>
                        {t('globals:buttons:delete-forever')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        registrations: state.eventRegistrations,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRegistrations: (id) => dispatch(fetchEventRegistrations(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);