import {
    FETCH_SETTINGS,
    FETCH_SETTINGS_FAILURE,
    FETCH_SETTINGS_SUCCESS,
    UPDATE_SETTINGS, UPDATE_SETTINGS_FAILURE,
    UPDATE_SETTINGS_SUCCESS
} from "../types/settingsTypes";

const initialState = {
    loading: true,
    error: {},
    settings: [],
};

const settingsReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_SETTINGS:
            return {
                ...state,
                settings: [],
                error: {},
                loading: true,
            };

        case FETCH_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: action.settings,
                error: {},
            };
        case FETCH_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                settings: [],
                error: {
                    code: action.errCode,
                    message: action.errMsg,
                }
            };
        case UPDATE_SETTINGS:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: action.settings,
                error: {},
            };
        case UPDATE_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: {
                    code: action.errCode,
                    message: action.errMsg,
                }
            };
        default:
            return state;
    }
}

export default settingsReducer;
