import React, {useContext, useState} from "react";
import Api from "../utils/Api";
import debounce from "lodash.debounce";

const authContext = React.createContext(undefined);

export const useAuth = () => {
    return useContext(authContext);
}

export const AuthProvider = ({ children }) => {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

export const useProvideAuth = () => {
    const [loggedIn, setLoggedIn] = useState(false);

    const check = debounce(async () => {
            return Api.get("/v2/auth/login_state", {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            })
                .then((r) => {
                    setLoggedIn(r.data.valid);
                    console.debug("Login state:", r.data.valid);

                    return r.data.valid;
                })
                .catch((e) => {
                    if(e.response?.status === 401) {
                        setLoggedIn(false);
                    }
                    return false;
                })
        }, 300);

    return {
        loggedIn,
        check
    };
}

export const saveToken = (token) => {
    return localStorage.setItem("authtoken", token);
}

export const getToken = () => {
    return localStorage.getItem("authtoken");
}

export const removeToken = () => {
    return localStorage.removeItem("authtoken");
}

export const saveTenant = (tenant) => {
    return localStorage.setItem("tenant", JSON.stringify(tenant));
}

export const getTenant = () => {
    return JSON.parse(localStorage.getItem("tenant"));
}

export const removeTenant = () => {
    return localStorage.removeItem("tenant");
}