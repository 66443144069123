import {Link} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

import EditIcon from '@material-ui/icons/Edit';
import {Link as RouterLink} from "react-router-dom";
import DataTable from "../../../components/DataTable";

const AdminUserListTable = (props) => {
    const {t} = useTranslation();

    const columns = [
        {
            selector: row => row['email'],
            name: t('pages:admin_user:list:table.email'),
            sortable: true
        },
        {
            selector: row => row['first_name'],
            name: t('pages:admin_user:list:table.firstname'),
            sortable: true,
        },
        {
            selector: row => row['last_name'],
            name: t('pages:admin_user:list:table.lastname'),
            sortable: true,
        },
        {
            selector: row => row['manageable_languages'],
            name: t('pages:admin_user:list:table.manageable_languages'),
            sortable: false,
            cell: ({manageable_languages}) => {
                let value = "";
                if(manageable_languages && manageable_languages.length > 0) {
                    manageable_languages.forEach((lang) => {
                        value = value + `${t(`globals:languages:${lang}`)}, `
                    })
                    return value.substr(0, value.length-2);
                }
                else {
                    return "...";
                }
            }
        },
        {
            cell: ({active}) => active === true ? t('pages:admin_user:list:table.state-active') : t('pages:admin_user:list:table.state-inactive'),
            name: t('pages:admin_user:list:table.state'),
            sortable: true,
        },
        {
            cell: ({id}) => <Link component={RouterLink} to={`/admin_users/${id}`} color={"textPrimary"}><EditIcon /></Link>,
            button: true,
        },
    ];

    return (
        <div style={{ width: '100%' }}>
            <DataTable
                title={t('pages:admin_user:list:table.heading')}
                columns={columns}
                data={props.userList.users}
                denseLayout={true}
                searchFields={['email', 'first_name', 'last_name']}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userList: state.users,
    };
}

export default connect(mapStateToProps)(AdminUserListTable);
