import {
    FETCH_EMAIL_TEMPLATES,
    FETCH_EMAIL_TEMPLATES_FAILURE,
    FETCH_EMAIL_TEMPLATES_SUCCESS
} from "../types/emailTemplatesTypes";

const initialState = {
    loading: true,
    error: {},
    templates: [],
};

const emailTemplatesReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_EMAIL_TEMPLATES:
            return {
                ...state,
                templates: [],
                loading: true,
            };

        case FETCH_EMAIL_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                templates: action.templates,
                error: {},
            };
        case FETCH_EMAIL_TEMPLATES_FAILURE:
            return {
                ...state,
                loading: false,
                templates: [],
                error: {
                    code: action.errCode,
                    message: action.errMsg,
                }
            };
        default:
            return state;
    }
}

export default emailTemplatesReducer;
