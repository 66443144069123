import React, {useState} from "react";
import {Divider, FormHelperText, TextField, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {useDebouncedEffect} from "../../../../../helper/useDebouncedEffect";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main
    },
    fieldInput: {
        marginTop: '10px'
    },
    divider: {
        marginTop: '5px',
        marginBottom: '10px',
    }
}));

const External = ({setExternal, values}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const [state, setState] = useState(values);

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    useDebouncedEffect(() => {
        setExternal({
            completed: state?.external_url !== "",
            values: state,
        })
    }, [state], 300);

    const fields = [
        {
            label: t('pages:event:create:information:external.form.link'),
            name: "external_url"
        }
    ]

    return (
        <div style={{marginTop: '30px'}}>
            <Typography variant={"h4"} className={classes.title}>{t('pages:event:create:information:external.title')}</Typography>
            <Divider className={classes.divider} />
            <Typography variant={"subtitle2"}>{t('pages:event:create:information:external.description')}</Typography>
            {fields.map((field, index) => {
                return (
                    <React.Fragment key={index}>
                        <TextField
                            name={field.name}
                            value={state[field.name] ?? ""}
                            label={field.label}
                            fullWidth
                            size={"small"}
                            onChange={handleChange}
                            className={classes.fieldInput}
                            multiline={field.multiline ? field.multiline : false}
                            rows={7}
                        />
                        {field.help && <FormHelperText>{field.help}</FormHelperText>}
                    </React.Fragment>
                )
            })}
        </div>
    );
}

export default External;