import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Cancel from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    heading: {
        marginBottom: '20px',
        marginTop: '10px'
    },
    icon: {
        color: '#ffffff',
        backgroundColor: '#777777'
    },
    description: {
        textAlign: 'center'
    }
}));



const NotFound = () => {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.icon}>
                    <Cancel />
                </Avatar>
                <Typography className={classes.heading} component="h1" variant="h5">
                    Nicht gefunden
                </Typography>
                <p className={classes.description}>Die von Ihnen aufgerufene Seite scheint nicht zu existieren, bitte prüfen Sie die eingegebene URL.</p>
            </div>
        </Container>
    );
}

export default NotFound;