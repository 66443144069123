import {
    FETCH_SINGLE_EMAIL_TEMPLATE,
    FETCH_SINGLE_EMAIL_TEMPLATE_FAILURE,
    FETCH_SINGLE_EMAIL_TEMPLATE_SUCCESS,
    UPDATE_SINGLE_EMAIL_TEMPLATE,
    UPDATE_SINGLE_EMAIL_TEMPLATE_FAILURE,
    UPDATE_SINGLE_EMAIL_TEMPLATE_SUCCESS
} from "../types/singleEmailTemplateTypes";

const initialState = {
    loading: true,
    error: {},
    template: [],
};

const singleEmailTemplateReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_SINGLE_EMAIL_TEMPLATE:
            return {
                ...state,
                template: [],
                loading: true,
            };

        case FETCH_SINGLE_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                template: action.template,
                error: {},
            };
        case FETCH_SINGLE_EMAIL_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                template: [],
                error: {
                    code: action.errCode,
                    message: action.errMsg,
                }
            };
        case UPDATE_SINGLE_EMAIL_TEMPLATE:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_SINGLE_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                template: action.template,
                error: {},
            };
        case UPDATE_SINGLE_EMAIL_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: {
                    code: action.errCode,
                    message: action.errMsg,
                }
            };
        default:
            return state;
    }
}

export default singleEmailTemplateReducer;
