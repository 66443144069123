import React, {useEffect, useState} from "react";
import ReactDataTable from "react-data-table-component";
import {useTranslation} from "react-i18next";
import {Box, Chip, Divider, FormControlLabel, Switch, TextField, Typography} from "@material-ui/core";

const DataTable = ({title = "", columns, data, searchFields = [], denseLayout = true, showLayoutSwitch = true}) => {
    const {t} = useTranslation();
    const [dense, setDense] = useState(denseLayout);


    const [filteredData, setFilteredData] = useState(data);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
      setFilteredData(data);
    }, [data]);


    const handleSearch = (e) => {
      const query = e.target.value;
      setSearchQuery(query);

      if(query === "") {
        setFilteredData(data);
      }
      else {
        let resultSet = [];
        searchFields.forEach((field) => {
          const fieldResult = data.filter(el => el[field].toLowerCase().indexOf(query.toLowerCase()) !== -1);
          resultSet.push(fieldResult);
        });

        resultSet = resultSet.flat();


        let uniqueResultSet = [];
        if(resultSet.length > 0) {
          const map = new Map();
          for (const item of resultSet) {
            if (!map.has(item.id)) {
              map.set(item.id, true);    // set any value to Map
              uniqueResultSet.push(item);
            }
          }
        }



        setFilteredData(uniqueResultSet);
      }
    }


    return (
        <>
            <Box style={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant={"h6"} component={"h2"}>
                {title} {searchQuery.length > 0 && <Chip size="small" label={t('components:dataTable:searchActive')} color={"primary"} variant={"outlined"} />}
              </Typography>
              <TextField
                label={t('components:dataTable:searchBox')}
                size={"small"}
                variant={"outlined"}
                onChange={handleSearch}
              />
            </Box>
          {showLayoutSwitch &&
            <FormControlLabel
              control={
                <Switch
                  checked={dense}
                  onChange={() => { setDense(!dense); }}
                  name="denseLayout"
                  color="primary"
                  size={"small"}
                />
              }
              label={t('components:dataTable:switchDenseLayout')}
              color={"primary"}
              style={{paddingTop: '10px', paddingLeft: '5px'}}
            />
          }
            <Divider style={{marginTop: '10px', marginBottom: '10px'}} />
            <ReactDataTable
                columns={columns}
                data={filteredData}
                pagination={true}
                fixedHeader={true}
                highlightOnHover={true}
                dense={dense}
                paginationComponentOptions={{
                    rowsPerPageText: t('components:dataTable:rowsPerPage'),
                    rangeSeparatorText: t('components:dataTable:rangeSeparator'),
                }}
                noDataComponent={t('components:dataTable:noData')}
                paginationRowsPerPageOptions={[10, 25, 50]}
                paginationPerPage={25}
            />
        </>

    )
}

export default DataTable;
