import {FETCH_SINGLE_USER, FETCH_SINGLE_USER_FAILURE, FETCH_SINGLE_USER_SUCCESS} from "../types/singleUserTypes";

const initialState = {
    loading: true,
    error: {},
    user: [],
};

const singleUserReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_SINGLE_USER:
            return {
                ...state,
                user: [],
                loading: true,
            };
        case FETCH_SINGLE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.user,
                error: {},
            };
        case FETCH_SINGLE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                user: [],
                error: {
                    code: action.errCode,
                    message: action.errMsg,
                }
            };
        default:
            return state;
    }
}

export default singleUserReducer;
