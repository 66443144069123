import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import {COLORS} from "./colors";

export const mainTheme = createMuiTheme({
    palette: {
        primary: {
            main: COLORS.primary.main
        },
        secondary: {
            main: COLORS.secondary.main,
        },
    },
    typography: {
        h4: {
            fontSize: '1.5em',
        },
    }
});
