import React from 'react';
import Template from "../Base/Template";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

const Dashboard = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Template title={t('pages:dashboard:title')}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Typography variant={"h4"} style={{marginBottom: '15px'}}>{t('pages:dashboard:heading')}</Typography>
                    <Typography variant={"subtitle1"}>{t('pages:dashboard:description')}</Typography>
                </Paper>
            </Grid>
        </Template>
    );
};

export default Dashboard;