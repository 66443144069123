import API from "../../utils/Api";

import {getTenant, getToken} from "../../helper/AuthHelper";
import {
    FETCH_EMAIL_TEMPLATES,
    FETCH_EMAIL_TEMPLATES_FAILURE,
    FETCH_EMAIL_TEMPLATES_SUCCESS
} from "../types/emailTemplatesTypes";

export const fetchTemplates = () => {

    const tenantId = getTenant().id;

    return (dispatch) => {
        dispatch(fetchTemplatesStart());
        API.get(`/v2/tenants/${tenantId}/email_templates`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
            .then((response) => {
                dispatch(fetchTemplatesSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchTemplatesFailure(error));
            })
    }
}

export const fetchTemplatesStart = () => {
    return {
        type: FETCH_EMAIL_TEMPLATES
    };
}


export const fetchTemplatesSuccess = (data) => {
    return {
        type: FETCH_EMAIL_TEMPLATES_SUCCESS,
        templates: data.templates,
    }
};

export const fetchTemplatesFailure = (error) => {
    return {
        type: FETCH_EMAIL_TEMPLATES_FAILURE,
        errMsg: error.message,
        errCode: error.response ? error.response.status ?? -1 : -1,
    }
}

