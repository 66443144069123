import React, {useState} from "react";
import {
    Button,
    TextField
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import LanguageSelect from "../../../components/LanguageSelect";
import SaveIcon from '@material-ui/icons/Save';
import {useSnackbar} from "notistack";
import Api from "../../../utils/Api";
import {Redirect} from "react-router-dom";
import {getTenant} from "../../../helper/AuthHelper";

const useStyles = makeStyles(() => ({
    textField: {
        marginTop: '20px'
    },
    selectField: {
        marginTop: '20px',
        width: '100%'
    },
    button: {
        marginTop: '25px'
    }
}));

const Form = () => {

    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();

    const [created, setCreated] = useState(false);
    const [state, setState] = useState({
        language: 'de',
        tenant: getTenant().id
    });
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        try {
            const response = await Api.post(`/v2/users`, state);
            if(response.status === 201) {
                enqueueSnackbar(t('pages:user:create:notifier-success'), {variant: "success"});
                setCreated(true);
            }
        }
        catch(e) {
            console.debug("User creation failed.", e);
            enqueueSnackbar(t('pages:user:create:notifier-failure'), {variant: "warning"});
        }
    }


    if(created === true) {
        return <Redirect to={"/users"} />
    }

    return (
        <div>
            <TextField
                name={"email"}
                label={t('pages:user:form.email')}
                value={state.email ?? ""}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                name={"first_name"}
                label={t('pages:user:form.first_name')}
                value={state.first_name ?? ""}
                onChange={handleChange}
                className={classes.textField}
                fullWidth
            />
            <TextField
                name={"last_name"}
                label={t('pages:user:form.last_name')}
                value={state.last_name ?? ""}
                onChange={handleChange}
                className={classes.textField}
                fullWidth
            />
            <TextField
                name={"company_name"}
                label={t('pages:user:form.company_name')}
                value={state.company_name ?? ""}
                onChange={handleChange}
                className={classes.textField}
                fullWidth
            />
            <TextField
                name={"postalcode"}
                label={t('pages:user:form.postalcode')}
                value={state.postalcode ?? ""}
                onChange={handleChange}
                className={classes.textField}
                fullWidth
            />
            <TextField
                name={"city"}
                label={t('pages:user:form.city')}
                value={state.city ?? ""}
                onChange={handleChange}
                className={classes.textField}
                fullWidth
            />
            <LanguageSelect className={classes.selectField} value={state.language} onChange={handleChange} labelTranslationKey={"pages:user:form.language"} />
            <br />
            <Button
                variant={"contained"}
                color={"primary"}
                startIcon={<SaveIcon />}
                className={classes.button}
                onClick={handleSubmit}
            >
                {t('globals:buttons:create')}
            </Button>
        </div>
    );
};

export default Form;