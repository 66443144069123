import React from "react";
import './App.scss';
import {Helmet} from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import ProtectedRoute from "./helper/ProtectedRoute";
import Dashboard from "./views/Dashboard/Dashboard";
import Login from "./views/Login/Login";
import UserList from "./views/User/UserList/UserList";
import EventList from "./views/Event/EventList/EventList";
import EventCreate from "./views/Event/EventCreate/EventCreate";
import NotFound from "./views/NotFound/NotFound";
import Settings from "./views/Settings/Settings";
import {SnackbarProvider} from "notistack";
import EmailList from "./views/Settings/EmailList/EmailList";
import EmailCreate from "./views/Settings/EmailCreate/EmailCreate";
import EmailEdit from "./views/Settings/EmailEdit/EmailEdit";
import Verify from "./views/Verify/Verify";
import UserEdit from "./views/User/UserEdit/UserEdit";
import UserCreate from "./views/User/UserCreate/UserCreate";
import EventEditLoader from "./views/Event/EventEdit/EventEditLoader";
import EventRegistrations from "./views/Event/EventRegistrations/EventRegistrations";
import AdminUserList from "./views/AdminUser/UserList/AdminUserList";
import AdminUserCreate from "./views/AdminUser/UserCreate/AdminUserCreate";
import AdminUserEdit from "./views/AdminUser/UserEdit/AdminUserEdit";
import EventStats from "./views/Event/EventStats/EventStats";

function App() {

    const { t } = useTranslation();

    return (

            <BrowserRouter>
                <SnackbarProvider
                    autoHideDuration={4000}
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <React.Fragment>
                        {/* Set default html tags */}
                        <Helmet titleTemplate={t('app:htmltitle.long')} defaultTitle={t('app:htmltitle.short')}>
                            <meta name="description" content="Administrative Oberfläche" />
                            <meta name="robots" content="noindex, nofollow" />
                        </Helmet>

                        <Switch>
                            <Route exact path={"/login"} component={Login} />
                            <Route exact path={"/verify"} component={Verify} />

                            <ProtectedRoute exact path={"/"} component={Dashboard} />

                            <ProtectedRoute exact path={"/users"} component={UserList} />
                            <ProtectedRoute exact path={"/users/create"} component={UserCreate} />
                            <ProtectedRoute exact path={"/users/:id"} component={UserEdit} />

                            <ProtectedRoute exact path={"/admin_users"} component={AdminUserList} />
                            <ProtectedRoute exact path={"/admin_users/create"} component={AdminUserCreate} />
                            <ProtectedRoute exact path={"/admin_users/:id"} component={AdminUserEdit} />

                            <ProtectedRoute exact path={"/events"} component={EventList} />
                            <ProtectedRoute exact path={"/events/create"} component={EventCreate} />
                            <ProtectedRoute exact path={"/events/:id"} component={EventEditLoader} />
                            <ProtectedRoute exact path={"/events/:id/registrations"} component={EventRegistrations} />
                            <ProtectedRoute exact path={"/events/:id/stats"} component={EventStats} />

                            <ProtectedRoute exact path={"/settings/tenant"} component={Settings} />
                            <ProtectedRoute exact path={"/settings/email-templates"} component={EmailList} />
                            <ProtectedRoute exact path={"/settings/email-templates/create"} component={EmailCreate} />
                            <ProtectedRoute exact path={"/settings/email-templates/:id"} component={EmailEdit} />

                            <Route component={NotFound} />
                        </Switch>


                    </React.Fragment>
                </SnackbarProvider>
            </BrowserRouter>
    );
}

export default App;
