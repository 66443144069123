import React from "react";
import {
    TableCell,
    TableRow,
    Typography
} from "@material-ui/core";
import {Trans, useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";
const useStyles = makeStyles(() => ({
    summaryContainer: {
        marginBottom: '20px'
    },
    descriptionContainer: {
        marginBottom: '30px',
    },
    table: {
        minWidth: 650,
    },
    tableHeading: {
        fontWeight: '700'
    }
}));

const Summary = ({globalValues, handleSubmit, creationState}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    // eslint-disable-next-line no-unused-vars
    const getTableBody = () => {
        let ret = [];

        for (const [key, value] of Object.entries(globalValues)) {
            ret.push(
                <TableRow key={key}>
                <TableCell component="th" scope="row">
                    {key}
                </TableCell>
                <TableCell align="right">{value.toString()}</TableCell>
            </TableRow>
            );
        }

        return ret;
    }

    return (
        <div>
            <Typography variant={"subtitle1"} component={"p"} className={classes.descriptionContainer} >
                <Trans i18nKey={"pages:event:create:summary:description"}/>
            </Typography>
            {/*
            <div className={classes.summaryContainer}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeading}>Bezeichnung</TableCell>
                            <TableCell className={classes.tableHeading} align="right">Wert</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getTableBody()}
                    </TableBody>
                </Table>
            </div>
            */}
            {creationState.error &&
            <Alert
                severity="warning"
                style={{marginTop: '10px'}}
            >
                {t('pages:event:create:summary:creationerror')}
            </Alert>
            }
        </div>
    )
};

export default Summary;