import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, InputLabel, MenuItem, Select,
  TextField, Typography
} from "@material-ui/core";
import {Trans, useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {fetchSingleEventIngest} from "../../../store/actions/singleEventActions";
import {connect} from "react-redux";
import StorageIcon from "@material-ui/icons/Storage";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(2),
  },
}));

const IngestDialog = (props) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  const classes = useStyles();
  const {id} = useParams();

  const [server, setServer] = useState("");

  useEffect(() => {
    if(open) {
      props.fetchIngest(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Button
        variant="outlined"
        className={classes.button}
        startIcon={<StorageIcon />}
        size={"small"}
        onClick={() => {setOpen(true); }}
      >
        {t('pages:event:edit:button.ingest-data')}
      </Button>
      <Dialog
        open={open}
        onClose={() => {setOpen(false);}}
      >
        <DialogTitle>{t('pages:event:edit:dialog.ingest-title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans i18nKey={"pages:event:edit:dialog.ingest-description"} />
            <br />
            <InputLabel style={{marginTop: 30, marginBottom: 5}}>{t('pages:event:edit:dialog.ingest-available-servers')}</InputLabel>
            <Select
              fullWidth
              value={server}
              onChange={({target}) => {setServer(target.value);}}
            >
              {props.ingest?.available_servers?.map((server) => {
                return (
                  <MenuItem value={server.fqdn} style={{display: 'block'}}>
                    <Typography variant={"subtitle1"} style={{fontWeight: 700}}>{server.fqdn}</Typography>
                    <Typography variant={"caption"}>{server.description ?? ""}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <TextField
              label={t('pages:event:edit:dialog.ingest-stream-server')}
              value={
                server !== "" ?
                  `rtmp://${server}/${props.ingest?.main?.application}` :
                  t('pages:event:edit:dialog.ingest-choose-server')
              }
              disabled
              fullWidth
              variant={"outlined"}
              style={{marginTop: '15px'}}
            />
            <TextField
              label={t('pages:event:edit:dialog.ingest-stream-key')}
              value={props.ingest?.main?.stream ?? ""}
              disabled
              fullWidth
              variant={"outlined"}
              style={{marginTop: '15px'}}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setOpen(false);}}>
            {t('globals:buttons:close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ingest: state.singleEvent.ingest,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIngest: (id) => dispatch(fetchSingleEventIngest(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IngestDialog);
