import {combineReducers} from "redux";
import loginReducer from "./loginReducer";
import usersReducer from "./usersReducer";
import eventsReducer from "./eventsReducer";
import singleEventReducer from "./singleEventReducer";
import settingsReducer from "./settingsReducer";
import emailTemplatesReducer from "./emailTemplatesReducer";
import singleEmailTemplateReducer from "./singleEmailTemplateReducer";
import singleUserReducer from "./singleUserReducer";
import eventRegistrationsReducer from "./eventRegistrationsReducer";

export default combineReducers({
    login: loginReducer,
    users: usersReducer,
    singleUser: singleUserReducer,
    events: eventsReducer,
    singleEvent: singleEventReducer,
    eventRegistrations: eventRegistrationsReducer,
    settings: settingsReducer,
    emailTemplates: emailTemplatesReducer,
    singleEmailTemplate: singleEmailTemplateReducer
});
