import React, {useEffect, useState} from 'react';
import Template from "../../Base/Template";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import {useSnackbar} from "notistack";
import EditIcon from "@material-ui/icons/Edit";
import {connect} from "react-redux";
import {fetchTemplate, updateTemplate} from "../../../store/actions/singleEmailTemplateActions";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    field: {
        marginBottom: '20px'
    },
    backButton: {
        marginBottom: theme.spacing(2),
    },
    button: {
        marginBottom: theme.spacing(2),
        maxWidth: '300px'
    }
}));

const EmailEdit = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    let {id} = props.match.params;

    const [state, setState] = useState({
        type: "",
        subject: "",
        content_html: "",
        content_text: "",
        language: "",
        active: false,
    });
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        props.fetchTemplate(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!props.template.loading && !props.template.error.code){
            setState(props.template.template);

            if(submitted) {
                enqueueSnackbar(t('pages:settings:email:edit:notifier.success'), {variant: "success"});
            }
        }
        else if(props.template.error.code) {
            if(submitted) {
                enqueueSnackbar(t('pages:settings:email:edit:notifier.failure'), {variant: "error"});
            }
            else {
                enqueueSnackbar(t('pages:settings:email:edit:notifier.loading-failure'), {variant: "error"});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.template]);


    const handleChange = (e) => {
      setState({
          ...state,
          [e.target.name]: e.target.value,
      });
    };
    const handleCheckboxChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.checked,
        });
    };

    const handleSubmit = () => {
        props.updateTemplate(id, state);
        setSubmitted(true);
    }

    return (
        <Template title={t('pages:settings:email:edit:title')}>
            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    size={"small"}
                    className={classes.backButton}
                    startIcon={<ArrowBackIcon />}
                    component={RouterLink}
                    to={"/settings/email-templates"}
                >
                    {t('globals:buttons:backtooverview')}
                </Button>
                <Paper className={classes.paper}>
                    <Typography variant={"h4"} style={{marginBottom: '20px'}}>{t('pages:settings:email:edit:heading')}</Typography>
                    <FormControl className={classes.field}>
                        <InputLabel id="email-type-label">{t('pages:settings:email:form.type')}</InputLabel>
                        <Select
                            labelId="email-type-label"
                            name={"type"}
                            value={state.type}
                            onChange={handleChange}
                        >
                            <MenuItem value={"user_verification"}>{t('pages:settings:email:form.type-userverification')}</MenuItem>
                            <MenuItem value={"event_registration"}>{t('pages:settings:email:form.type-eventregistration')}</MenuItem>
                            <MenuItem value={"event_reminder"}>{t('pages:settings:email:form.type-eventreminder')}</MenuItem>
                            <MenuItem value={"user_password_reset"}>{t('pages:settings:email:form.type-passwordreset')}</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        className={classes.field}
                        label={t('pages:settings:email:form.subject')}
                        name={"subject"}
                        value={state.subject}
                        onChange={handleChange}
                    />
                    <FormControl className={classes.field}>
                        <InputLabel id="email-language-label">{t('pages:settings:email:form.language')}</InputLabel>
                        <Select
                            labelId="email-language-label"
                            name={"language"}
                            value={state.language}
                            onChange={handleChange}
                        >
                            <MenuItem value={"de"}>{t('globals:languages:german')}</MenuItem>
                            <MenuItem value={"en"}>{t('globals:languages:english')}</MenuItem>
                            <MenuItem value={"fr"}>{t('globals:languages:french')}</MenuItem>
                            <MenuItem value={"it"}>{t('globals:languages:italian')}</MenuItem>
                            <MenuItem value={"pl"}>{t('globals:languages:polish')}</MenuItem>
                            <MenuItem value={"ru"}>{t('globals:languages:russian')}</MenuItem>
                            <MenuItem value={"es"}>{t('globals:languages:spanish')}</MenuItem>
                            <MenuItem value={"tr"}>{t('globals:languages:turkish')}</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        className={classes.field}
                        label={t('pages:settings:email:form.content_html')}
                        name={"content_html"}
                        multiline
                        rows={10}
                        value={state.content_html}
                        onChange={handleChange}
                    />
                    <TextField
                        className={classes.field}
                        label={t('pages:settings:email:form.content_text')}
                        name={"content_text"}
                        multiline
                        rows={10}
                        value={state.content_text}
                        onChange={handleChange}
                    />
                    <FormControlLabel
                        className={classes.field}
                        control={
                            <Checkbox
                                name={"active"}
                                color="primary"
                                checked={state.active}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label={t('pages:settings:email:form.active')}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<EditIcon />}
                        onClick={handleSubmit}
                    >
                        {t('globals:buttons:save-update')}
                    </Button>
                </Paper>
            </Grid>
        </Template>
    );
};

const mapStateToProps = (state) => {
    return {
        template: state.singleEmailTemplate,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTemplate: (id) => dispatch(fetchTemplate(id)),
        updateTemplate: (id, data) => dispatch(updateTemplate(id, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailEdit);
