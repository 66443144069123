import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {
    Avatar,
    Box,
    Button,
    CssBaseline,
    MenuItem,
    Link,
    Select,
    TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LockIcon from '@material-ui/icons/Lock';

import background from "../../assets/login-background.jpg";
import logo from "../../assets/logo.svg";
import LanguageSwitcher from "../Base/LanguageSwitcher";
import {connect} from "react-redux";
import {login, loginReset} from "../../store/actions/loginActions";
import {Alert} from "@material-ui/lab";
import {Redirect} from "react-router-dom";
import {useAuth} from "../../helper/AuthHelper";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'left center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    formControl: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        minWidth: 120,
    },
    formInputLabel: {
        marginTop: '-10px'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        maxWidth: '120px',
        marginBottom: '30px'
    },
    alert: {
        width: '100%',
        marginBottom: '15px'
    }
}));

const Footer = () => {
    const {t} = useTranslation();

    return (
        <Grid container style={{marginTop: '35px'}}>
            <Grid item>
                <Link href={"https://www.joy-event-media.de/impressum/"} variant="body2" target={"_blank"} rel={"noopener noreferrer"}>
                    {t('pages:login:legallinks')}
                </Link>
            </Grid>
        </Grid>
    )
}

const Login = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {check, loggedIn} = useAuth();

    const [formState, setFormState] = useState({
        email: "",
        password: "",
        tenant_id: "",
        submitEnabled: false,
    });
    const handleFormChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    }

    const handleLoginAttempt = (e) => {
        e.preventDefault();

        props.login(formState.email, formState.password, formState.tenant_id);
    }

    useEffect(() => {
        props.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(formState.email !== "" && formState.password !== "") {
            setFormState({
                ...formState,
                submitEnabled: true,
            });
        }
        else {
            setFormState({
                ...formState,
                submitEnabled: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.email, formState.password, formState.tenant_id]);

    useEffect( () => {
        if(props.loginState.user !== null) {
            check();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.loginState.user]);

    if(loggedIn === true){
        return <Redirect to={"/"} />
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <img src={logo} className={classes.logo} alt={"Logo"} />
                    <Avatar className={classes.avatar}>
                        <LockIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t('pages:login:title')}
                    </Typography>
                    <Typography component="p" variant="subtitle1" style={{marginTop: '10px'}}>
                        {t('pages:login:subtitle')}
                    </Typography>
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={handleLoginAttempt}
                    >
                        {props.loginState.user === null && props.loginState.tenants !== null && props.loginState.tenants.length > 0 ?
                            <>
                                <Typography variant={"subtitle1"}>{t('pages:login:tenanthelptext')}</Typography><br />
                                <Select
                                    labelId={"tenant_id_label"}
                                    name={"tenant_id"}
                                    value={formState.tenant_id}
                                    onChange={handleFormChange}
                                    fullWidth
                                >
                                    {props.loginState.tenants.map((tenant) => {
                                        return <MenuItem key={tenant.id} value={tenant.id}>{tenant.name}</MenuItem>;
                                    })}
                                </Select>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={!formState.submitEnabled || props.loginState.loading}
                                >
                                    {t('pages:login:form.submittenant')}
                                </Button>
                            </>
                            :
                            <>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={t('pages:login:form.email')}
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={formState.email}
                                    onChange={handleFormChange}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={t('pages:login:form.password')}
                                    type="password"
                                    autoComplete="current-password"
                                    value={formState.password}
                                    onChange={handleFormChange}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={!formState.submitEnabled || props.loginState.loading}
                                >
                                    {t('pages:login:form.submit')}
                                </Button>

                                {props.loginState.error.code &&
                                <Alert severity={"warning"} className={classes.alert}>
                                    <b>{t('pages:login:alert.failure.title')}</b><br/>
                                    {t('pages:login:alert.failure.message')}
                                </Alert>
                                }
                            </>
                        }
                    </form>

                    {/*
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                        */}
                    <Box mt={2} pt={4} style={{borderTop: '1px solid #ccc'}} width={"100%"}>
                        <LanguageSwitcher fullWidth={false}  />
                        <Footer />
                    </Box>
                    </div>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        loginState: state.login,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (email, password, tenant_id) => dispatch(login(email, password, tenant_id)),
        reset: () => dispatch(loginReset()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
