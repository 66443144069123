import {Link} from "@material-ui/core";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

import EditIcon from '@material-ui/icons/Edit';
import {Link as RouterLink} from "react-router-dom";
import DataTable from "../../../components/DataTable";
import {DateTime} from "luxon";
import {DATETIME_MED} from "luxon/src/impl/formats";
import {BarChart} from "@material-ui/icons";

const EventListTable = (props) => {
    const {t, i18n} = useTranslation();
    const [parsedData, setParsedData] = useState([]);

    /* Pre-format DB values for table sorting */
    useEffect(() => {
        const data = props.eventList.events;

        data.map((event) => {
            const dt = DateTime.fromISO(event.starts_at);
            event.starts_at_formatted = dt.setLocale(i18n.language).toLocaleString(DATETIME_MED);

            const type = event.type;
            switch(type) {
                case "live":
                    event.type_formatted = t('pages:event:list:type.live');
                    break;
                case "external":
                    event.type_formatted = t('pages:event:list:type.external');
                    break;
                case "vod":
                    event.type_formatted = t('pages:event:list:type.vod');
                    break;
                default:
                    event.type_formatted = "Unknown";
                    break;
            }

            event.language_formatted = t(`globals:languages:${event.language}`);

            return event;
        })

        setParsedData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.eventList.events, i18n.language]);

    const columns = [
        {
            selector: row => row['title'],
            name: t('pages:event:list:table.title'),
            sortable: true
        },
        {
            name: t('pages:event:list:table.start_date'),
            selector: row => row['starts_at_formatted'],
            sortable: true,
            sortFunction: (rowA, rowB) => {
                const a = rowA.starts_at;
                const b = rowB.starts_at;

                if (a > b) {
                    return 1;
                }

                if (b > a) {
                    return -1;
                }

                return 0;
            }
        },
        {
            name: t('pages:event:list:table.type'),
            selector: row => row['type_formatted'],
            sortable: true,
        },
        {
            name: t('pages:event:list:table.language'),
            selector: row => row['language_formatted'],
            sortable: true,

        },
        {
            cell: ({id, type}) => {
                if(type === "external") {
                    return (
                      <>
                          <BarChart color={"disabled"} />
                          <Link component={RouterLink} to={`/events/${id}`} color={"textPrimary"}><EditIcon /></Link>
                      </>
                    )
                }
                return (
                  <>
                      <Link component={RouterLink} to={`/events/${id}/stats`} color={"textPrimary"}><BarChart /></Link>
                      <Link component={RouterLink} to={`/events/${id}`} color={"textPrimary"}><EditIcon /></Link>
                  </>
                );
            },
            button: true,
        },
    ];

    return (
        <div style={{ width: '100%' }}>
            <DataTable
                title={t('pages:event:list:table.heading')}
                columns={columns}
                data={parsedData}
                denseLayout={true}
                searchFields={['title']}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        eventList: state.events,
    };
}

export default connect(mapStateToProps)(EventListTable);
