import {
    LOGIN_RESET,
    LOGIN_USER,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_TENANT_REQUIRED
} from '../types/loginTypes';

const initialState = {
    loading: false,
    error: {},
    user: null,
    tenants: null,
};

const loginReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOGIN_USER:
            return {
                ...state,
                user: null,
                loading: true,
            };
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.user,
                error: {},
                tenants: action.tenants
            };
        case LOGIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                user: null,
                tenants: null,
                error: {
                    code: action.errCode,
                    message: action.errMsg,
                }
            };
        case LOGIN_USER_TENANT_REQUIRED:
            return {
                ...state,
                loading: false,
                user: null,
                tenants: action.tenants,
                verify_token: action.verify_token,
                error: {},
            };
        case LOGIN_RESET:
            return {
                ...state,
                loading: false,
                error: {}
            }
        default:
            return state;
    }
}

export default loginReducer;
