/* React Core */
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

/* Typography */
import "@fontsource/source-sans-pro";
import './index.scss';

/* Translation  */
import './translation/i18n';

/* Helmet */
import {HelmetProvider} from "react-helmet-async";

/* Redux store */
import {store} from "./store";

/* Theming */
import { ThemeProvider } from '@material-ui/core/styles';
import {mainTheme} from "./theme/theme";

/* Components and views */
import App from './App';
import PageLoading from "./views/PageLoading";
import {Provider} from "react-redux";
import {AuthProvider} from "./helper/AuthHelper";

ReactDOM.render(
  <React.StrictMode>
      <HelmetProvider>
          <Provider store={store}>
              <ThemeProvider theme={mainTheme}>
                  <Suspense fallback={<PageLoading />}>
                      <AuthProvider>
                        <App />
                      </AuthProvider>
                  </Suspense>
              </ThemeProvider>
          </Provider>
      </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
