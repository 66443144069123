import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {
    Box,
    Button, Divider,
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Link as RouterLink, useParams} from "react-router-dom";

import {fetchSingleEvent} from "../../../store/actions/singleEventActions";
import {connect} from "react-redux";
import Template from "../../Base/Template";
import LivestreamLiveStats from "./LivestreamLiveStats";
import MediaStats from "./MediaStats";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginBottom: theme.spacing(2),
    },
}));

const EventStats = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {id} = useParams();

    useEffect(() => {
        props.fetchEvent(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
      <Template title={`${t('pages:event:stats:title')}${props.event.title ? ` - ${props.event.title}` : ""}`}>
        <Grid item xs={12}>
            <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      startIcon={<ArrowBackIcon />}
                      size={"small"}
                      component={RouterLink}
                      to={"/events"}
                    >
                        {t('globals:buttons:backtooverview')}
                    </Button>
                </div>
            </Box>
            {props.event?.type === "live" && <><LivestreamLiveStats /><Divider style={{marginTop: 15}} /></>}
            {props.event.id && <MediaStats /> }
        </Grid>
      </Template>
    );
};

const mapStateToProps = (state) => {
    return {
        event: state.singleEvent.event
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchEvent: (id) => dispatch(fetchSingleEvent(id, true))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventStats);
