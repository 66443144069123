export const APP_LANGUAGE_DE = {
    app: {
        "htmltitle.short": "Streamingapp Admin",
        "htmltitle.long": "%s - Streamingapp Admin",
        "lngswitcher.title": "Sprache wechseln",
        "lngswitcher.german": "Deutsch",
        "lngswitcher.english": "Englisch",
    },
    nav: {
        "dashboard": "Übersicht",
        "events": "Events",
        "users": "Benutzer",
        "admin_users": "Admin-Benutzer",
        "settings": "Einstellungen",
        "settings.email": "E-Mails",
        "settings.tenant": "Stammdaten",
        "logout": "Ausloggen",
    },
    globals: {
        languages: {
            "german": "Deutsch",
            "english": "Englisch",
            "french": "Französisch",
            "italian": "Italienisch",
            "polish": "Polnisch",
            "russian": "Russisch",
            "spanish": "Spanisch",
            "turkish": "Türkisch",
            "de": "Deutsch",
            "en": "Englisch",
            "fr": "Französisch",
            "it": "Italienisch",
            "pl": "Polnisch",
            "ru": "Russisch",
            "es": "Spanisch",
            "tr": "Türkisch"
        },
        buttons: {
            "new": "Neu",
            "cancel": "Abbrechen",
            "close": "Schließen",
            "backtooverview": "Zurück zur Übersicht",
            "save-update": "Aktualisieren",
            "create": "Erstellen",
            "delete": "Löschen",
            "delete-forever": "Unwiderruflich löschen"
        }
    },
    pages: {
        dashboard: {
            "title": "Übersicht",
            "heading": "Willkommen im Administrationsbereich.",
            "description": "Bitte wählen Sie links im Menü die gewünschte Aktion aus."
        },
        login: {
            "title": "Login",
            "subtitle": "Bitte melden Sie sich an, um die Anwendung zu nutzen.",
            "form.email": "E-Mail-Adresse",
            "form.password": "Passwort",
            "form.tenant": "Mandant",
            "form.submit":"Einloggen",
            "form.submittenant": "Mandant auswählen und einloggen",
            "button-sso-login": "Mit JOY Single-Sign-On fortfahren",
            "tenanthelptext": "Ihrem Account sind mehrere Mandanten zugewiesen. Für welchen möchten Sie sich anmelden?",
            "legallinks": "Impressum / Datenschutz",
            "alert.failure.title": "Login fehlgeschlagen",
            "alert.failure.message": "Bitte überprüfen Sie Ihre Anmeldedaten und versuchen es erneut.",
            "alert.oauth.invalid.title": "Ungültige Anfrage",
            "alert.oauth.invalid.message": "Es wurde eine ungültige Login-Anfrage übergeben. Bitte versuchen Sie es erneut.",
            "alert.loggedin.title": "Sie sind bereits angemeldet.",
            "alert.loggedin.message": "Klicken Sie hier um zur Anwendung zu gelangen.",
            "button.backlogin": "Zurück zum Login"
        },
        user: {
            "form.first_name": "Vorname",
            "form.last_name": "Nachname",
            "form.language": "Sprache",
            "form.company_name": "Firmenbezeichnung",
            "form.postalcode": "Postleitzahl",
            "form.city": "Stadt",
            "form.active": "Benutzer ist aktiv",
            "form.email": "E-Mail-Adresse",
            "form.active_not_allowed_message": "Aus Datenschutzgründen dürfen Sie den Aktiv-Status des Nutzers nicht ändern.",
            create: {
                "title": "Neuen Benutzer erstellen",
                "notifier-success": "Der Benutzer wurde erfolgreich erstellt.",
                "notifier-failure": "Der Benutzer konnte nicht erstellt werden, bitte prüfen Sie die Eingaben.",
            },
            list: {
                "title": "Benutzerübersicht",
                "table.heading": "Registrierte Benutzer",
                "table.email": "E-Mail",
                "table.firstname": "Vorname",
                "table.lastname": "Nachname",
                "table.state": "Status",
                "table.state-active": "Aktiv",
                "table.state-inactive": "Inaktiv",
                "table.language": "Sprache",
                "export-btn": "Benutzer exportieren",
                export: {
                    "dialog-title": "Benutzer exportieren",
                    "dialog-description": "Bitte stellen Sie vor dem Start des Exports sicher, dass Sie die hier exportierten Daten ausschließlich zu <strong>datenschutzkonformen Zwecken</strong> nutzen.<br />Nutzerdaten dürfen ausschließlich an berechtigte Personen weitergegeben werden.",
                    "dialog-export-button": "Benutzer jetzt exportieren"
                }
            },
            edit: {
                "title": "Benutzer anpassen",
                "form.email-help": "Dieser Wert kann nicht geändert werden.",
                "dialog.delete-title": "Benutzer löschen?",
                "dialog.delete-description": "Wenn Sie den Benutzer löschen, werden alle ihm zugehörigen Daten unwiderruflich gelöscht. Dies umfasst unter anderem Eventregistrierungen und Eingaben bei interaktiven Features.<br /><br /><strong>Hinweis:</strong><br />Nach erfolgter Löschung kann der Nutzer ein neues Konto erstellen.",
                "notifier-delete-success": "Der Benutzer wurde erfolgreich gelöscht.",
                "notifier-delete-failure": "Der Benutzer konnte nicht gelöscht werden.",
                "notifier-failure": "Der Benutzer konnte nicht editiert werden.",
                "notifier-success": "Der Benutzer wurde erfolgreich bearbeitet.",
            }
        },
        admin_user: {
            "form.language": "Benutzer-Sprache",
            "form.active": "Benutzer ist aktiv",
            "form.email": "E-Mail-Adresse",
            "form.first_name": "Vorname",
            "form.last_name": "Nachname",
            "form.manageable_languages": "Verwaltbare Sprachen",
            "form.company_admin": "Unternehmens-Administrator",
            "form.company_admin_helper": "Unternehmens-Administratoren haben erweiterte Berechtigungen wie das Verwalten von E-Mail-Templates oder anderen Administratoren.",
            create: {
                "title": "Neuen Admin-Benutzer erstellen",
                "notifier-success": "Der Benutzer wurde erfolgreich erstellt.",
                "notifier-failure": "Der Benutzer konnte nicht erstellt werden, bitte prüfen Sie die Eingaben.",
            },
            list: {
                "title": "Admin-Benutzerübersicht",
                "table.heading": "Registrierte Administratoren",
                "table.email": "E-Mail",
                "table.firstname": "Vorname",
                "table.lastname": "Nachname",
                "table.state": "Status",
                "table.state-active": "Aktiv",
                "table.state-inactive": "Inaktiv",
                "table.manageable_languages": "Verwaltbare Sprachen",
            },
            edit: {
                "title": "Admin-Benutzer anpassen",
                "form.email-help": "Dieser Wert kann nicht geändert werden.",
                "dialog.delete-title": "Benutzer löschen?",
                "dialog.delete-description": "Wenn Sie den Benutzer löschen, werden alle ihm zugehörigen Daten unwiderruflich gelöscht.<br /><br /><strong>Hinweis:</strong><br />Nach erfolgter Löschung kann der Nutzer ein neues Konto erstellen.",
                "notifier-delete-success": "Der Benutzer wurde erfolgreich gelöscht.",
                "notifier-delete-failure": "Der Benutzer konnte nicht gelöscht werden.",
                "notifier-failure": "Der Benutzer konnte nicht editiert werden.",
                "notifier-success": "Der Benutzer wurde erfolgreich bearbeitet.",
            }
        },
        event: {
            "authtype.public": "Öffentlich",
            "authtype.shared_secret": "Geteiltes Passwort",
            "authtype.user": "Einzelnutzer",
            "button.back": "Zurück zur Übersicht",
            "button.stepback": "Zurück",
            "button.continue": "Weiter",
            "info.stepback": "Bisher eingegebene Daten könnten verloren gehen, wenn Sie zurück gehen.",
            information: {
                basic: {
                    "title": "Basisdaten",
                    "form.title": "Titel",
                    "form.slug": "Link-Endung",
                    "form.slug-adornment": "https://[ihre-domain.de]/event/",
                    "form.slug-help": "Beispiel: \"demoevent\" wird zu: https://[ihre-domain.de]/event/demoevent",
                    "form.description": "Beschreibungstext",
                    "form.description-help": "Der Beschreibungstext wird auf der Eventseite angezeigt. Hier können Sie beispielsweise eine inhaltliche Beschreibung oder Agenda einfügen.",
                    "form.slugtaken": "Diese Link-Endung ist bereits vergeben."
                },
            },
            list: {
                "title": "Eventübersicht",
                "table.heading": "Erstellte Events",
                "table.title": "Titel",
                "table.start_date": "Startdatum",
                "table.type": "Typ",
                "table.auth_type": "Anmeldeoption",
                "table.language": "Sprache",
                "button.new": "Neu",
                "type.live": "Live-Event",
                "type.external": "Externes Event",
                "type.vod": "Video-on-Demand",
            },
            registrations: {
                "title": "Event-Registrierungen",
                "table.heading": "Registrierte Nutzer",
                "table.first_name": "Vorname",
                "table.last_name": "Nachname",
                "table.company_name": "Unternehmen",
                "table.email": "E-Mail",
                "table.language": "Sprache",
                "dialog.title": "Registrierung löschen?",
                "dialog.description": "Wenn Sie die Registrierung löschen, kann der Benutzer nicht mehr auf dieses Event zugreifen.<br /><br /><strong>Hinweis:</strong><br />Nach erfolgter Löschung kann der Nutzer sich erneut registrieren.",
                "notifier-delete-success": "Die Registrierung wurde erfolgreich gelöscht.",
                "notifier-delete-failure": "Die Registrierung konnte nicht gelöscht werden.",
                "export-btn": "Benutzer exportieren",
                export: {
                    "dialog-title": "Benutzer exportieren",
                    "dialog-description": "Bitte stellen Sie vor dem Start des Exports sicher, dass Sie die hier exportierten Daten ausschließlich zu <strong>datenschutzkonformen Zwecken</strong> nutzen.<br />Nutzerdaten dürfen ausschließlich an berechtigte Personen weitergegeben werden.",
                    "dialog-export-button": "Benutzer jetzt exportieren"
                }
            },
            create: {
                "title": "Neues Event erstellen",
                "button.create": "Event erstellen",
                "button.stepback": "Zurück",
                "button.continue": "Weiter",
                "steps.metadata": "Informationen",
                "steps.type": "Event-Typ",
                "steps.viewers": "Zuschauer",
                "steps.interactive": "Interaktion",
                "steps.pricing": "Preisübersicht",
                "steps.summary": "Zusammenfassung",
                "notifier-success": "Das Event wurde erfolgreich erstellt.",
                "info.stepback": "Zuvor eingegebene Daten können verloren gehen, wenn Sie zurückgehen.",
                type: {
                    "description": "<strong>Willkommen im Event-Editor!</strong><br />Bitte wählen Sie im ersten Schritt aus, welche Art von Event Sie anlegen möchten.",
                    "live.title": "Live-Event",
                    "live.description": "Übertragen Sie Ihre Veranstaltung als Livestream an Ihre Zuschauer.",
                    "vod.title": "Video-on-Demand",
                    "vod.description": "Stellen Sie ein im Voraus erstelltes Video zum Abruf zur Verfügung.",
                    "external.title": "Externes Event",
                    "external.description": "Nutzen Sie unsere Plattform, um auf ein externes Event (z.B. eine Videokonferenz in Microsoft Teams, Zoom, o.ä.) zu verweisen."
                },
                information: {
                    "description": "Tragen Sie hier die relevanten Informationen zum Event ein.<br />Diese können selbstverständlich auch nach Erstellung des Events noch angepasst werden.",
                    vodInfo: {
                      "title": "Information für VOD-Inhalt",
                      "description": "Nach Erstellung des Events haben Sie die Möglichkeit den Videoinhalt hochzuladen."
                    },
                    external: {
                        "title": "Link zum externen Event",
                        "description": "Sie haben ausgewählt, dass Sie ein externes Event erstellen möchten. Bitte tragen Sie hier den Link ein, auf den Sie Ihre Zuschauer verweisen möchten.",
                        "form.link": "Link"
                    },
                    meta: {
                        "title": "Filteroptionen",
                        "form.language": "Sprache",
                        "form.language-help": "Wählen Sie hier aus, in welcher Sprache dieses Event abgehalten wird.",
                        "form.tags": "Tags / Schlagwörter",
                        "form.tags-help": "Sie können mehrere Schlagwörter durch Betätigen der Enter- / \"Komma\" oder Leer-Taste eingeben."
                    },
                    time: {
                        "title": "Zeitplan",
                        "notice-timezone": "Hinweis: Die hier eingegebenen Zeiten basieren auf Ihrer Browser-Zeitzone ({{timezone}}).",
                        "form.start_date": "Startzeitpunkt",
                        "form.end_date": "Geplanter Endzeitpunkt",
                        "form.end_date_hidden": "Der Endzeitpunkt soll nicht angezeigt werden",
                        "error.end_before_start": "Der Endzeitpunkt darf nicht kleiner oder gleich dem Startzeitpunkt sein.",
                        "error.invalid_date": "Ein eingegebenes Datum scheint ungültig zu sein."
                    },
                    protection: {
                        "title": "Zugangsbeschränkung",
                        "description": "Hier können Sie auswählen, wer Zugriff auf Ihr Event erhalten soll.",
                        "auth_type": "Art der Zugangsbeschränkung",
                        "shared_secret": "Passwort eingeben...",
                        "shared-secret-help": "Mit welchem Passwort sollen sich Nutzer für das Event anmelden können?"
                    },
                    basic: {
                        "title": "Basisinformationen",
                        "form.title": "Titel",
                        "form.slug": "Linkname",
                        "form.slug-adornment": "https://[your-domain.com]/event/",
                        "form.slug-help": "Beispiel: \"demoevent\" wird zu: https://[your-domain.com]/event/demoevent",
                        "form.description": "Beschreibungstext",
                        "form.description-help": "Der Beschreibungstext wird auf der Veranstaltungsseite angezeigt. Hier können Sie z.B. eine Inhaltsbeschreibung oder eine Agenda einfügen."
                    },
                },
                interactive: {
                    "description": "Machen Sie mehr aus Ihrem Event mit <strong>interaktiven Features</strong>.",
                    "descriptionexternal": "Sie haben ein externes Event angelegt, daher können Sie keine interaktiven Features hinzufügen.<br />Bitte klicken Sie auf \"Weiter\" um fortzufahren.",
                    "or": "-- oder --",
                    "button.choose": "Auswählen",
                    "questionbox.title": "Fragebox",
                    "questionbox.description": "Binden Sie ihre Zuschauer interaktiv in das Event ein, indem Sie sie Fragen stellen lassen.<br />Moderator:innen können optional entweder alle eingehenden Fragen oder vorher durch eine Redaktion ausgewählte Fragen auf einer übersichtlichen Oberfläche einsehen.",
                    "questionbox.mode_moderator": "Moderations-Modus (alle eingehenden Fragen werden angezeigt)",
                    "questionbox.mode_managed": "Redaktions-Modus (eingehende Fragen werden durch eine Redaktion vorsortiert)",
                    "chat.title": "Live-Chat",
                    "chat.description": "Der Live-Chat bietet die Möglichkeit die Interaktion zwischen Zuschauern zu fördern.<br />Aktuelle Sendungsthemen können aktiv und untereinander besprochen werden.",
                    "disabled.title": "Ohne Interaktion",
                    "disabled.description": "Für das Event ist kein interaktives Feature gewünscht."
                },
                viewers: {
                    "description": "Bitte wählen Sie im Folgenden aus, wie viele Zuschauer Sie für Ihr Event erwarten.<br />Falls Sie sich unsicher sind können Sie auch aus mehreren Flex-Optionen auswählen.",
                    "descriptionexternal": "Sie haben ein externes Event angelegt, daher können Sie keine Zuschauerdaten angeben.<br />Bitte klicken Sie auf \"Weiter\" um fortzufahren.",
                    "count.title": "Zuschauerzahl",
                    "alert.moreviewers": "<strong>Ihr Event scheint sehr gefragt zu sein.</strong><br />Bitte wenden Sie sich an unseren Support, wenn Sie ein Event mit mehr als 3.000 Zuschauern erstellen möchten.",
                    "overbooking.title": "Flex-Optionen",
                    "overbooking.description": "Nicht immer lässt sich genau sagen, wie viele Zuschauer Ihr Event anschauen werden.<br />Daher geben wir Ihnen hier die Möglichkeit auszuwählen, wie viele zusätzliche Zuschauer Sie im Fall der Fälle erlauben möchten.",
                    "overbooking.disabled": "Keine zusätzlichen Zuschauer erlauben",
                    "overbooking.50percent": "Bis zu 50 Prozent mehr Zuschauer erlauben",
                    "overbooking.100percent": "Bis zu 100 Prozent mehr Zuschauer erlauben",
                },
                pricing: {
                    "table.name": "Bezeichnung",
                    "table.count": "Anzahl",
                    "table.price": "Preis",
                    "table.total": "Gesamtsumme zzgl. USt.",
                    "description": "<strong>Wir sind fast fertig.</strong><br />Im folgenden sehen Sie eine Übersicht über die Kosten der von Ihnen angegebenen Eventdetails.",
                    "specialpricing": "Ihrem Account sind Sonderkonditionen zugeordnet.<br />Positionen, die abweichend von unseren Normalpreisen sind, sind mit einem Sternchen markiert."
                },
                summary: {
                    "description": "Vielen Dank, das war es schon fast!<br />Bitte klicken Sie auf \"Event erstellen\" um die Erstellung abzuschließen.",
                    "creationerror": "Bei der Erstellung des Events ist ein Fehler aufgetreten."
                }
            },
            edit: {
                "title": "Event bearbeiten",
                "button.registrations": "Registrierungen verwalten",
                "button.ingest-data": "Live-Ingest-Daten",
                "button.questionbox-data": "Fragebox",
                "notifier-loading-error": "Das Event konnte nicht geladen werden.",
                "notifier-error": "Das Event konnte nicht aktualisiert werden.",
                "notifier-success": "Das Event wurde erfolgreich aktualisiert.",
                "notifier-delete-success": "Das Event wurde erfolgreich gelöscht.",
                "notifier-delete-failure": "Das Event konnte nicht gelöscht werden.",
                "dialog.delete-title": "Event löschen?",
                "dialog.delete-description": "Wenn Sie das Event löschen, werden alle zugehörigen Daten unwiderruflich gelöscht. Dies umfasst unter anderem Eventregistrierungen.<br /><br /><strong>Hinweis:</strong><br />Nach erfolgter Löschung kann ein neues Event mit derselben Link-Endung erstellt werden.",
                "dialog.ingest-title": "Live-Ingest Informationen",
                "dialog.ingest-description": "Nutzen Sie diese Informationen, um einen Livestream zu diesem Event zu starten.<br /><strong>Teilen Sie diese Informationen nur mit berechtigten Personen.</strong>",
                "dialog.ingest-available-servers": "Verfügbare Ingest-Punkte",
                "dialog.ingest-choose-server": "Ingest-Punkt oben auswählen",
                "dialog.ingest-stream-server": "Stream-Server",
                "dialog.ingest-stream-key": "Stream-Key",
                "dialog.questionbox-title": "Fragebox-Links",
                "dialog.questionbox-description": "Nutzen Sie die folgenden Links, um Zugriff auf die Fragebox zu erteilen.<br /><strong>Teilen Sie diese Informationen nur mit berechtigten Personen.</strong>",
                "dialog.questionbox-moderator": "Panel-Link für Moderator (Nur freigegebene Fragen)",
                "dialog.questionbox-editor": "Panel-Link für Redaktion (Freigegebene und ausstehende Fragen)",
                information: {
                    "description": "Hier können Sie die relevanten Informationen des Events aktualisieren.",
                    upload: {
                        "title": "Dateiupload",
                        "description": "Laden Sie hier das Video für dieses Event hoch.<br /><br />Es werden folgende Formate akzeptiert:<br>- .mp4 (Video-Codecs: H264, Audio-Codecs: AAC, MP3)<br /><br />Wird ein Video mit anderem Format hochgeladen schlägt der Upload oder die Verarbeitung fehl.",
                        "upload-button": "Datei auswählen",
                        "alert-success": "Das Video wurde erfolgreich hochgeladen und wird nun verarbeitet.<br /><strong>Dieser Prozess kann je nach Videolänge mehrere Stunden in Anspruch nehmen.</strong>",
                        "alert-failure": "Beim Hochladen ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen es erneut.<br />Falls der Fehler wiederholt auftritt kontaktieren Sie bitte den Support.",
                        "alert-current-video-transcoding": "Ihr hochgeladenes Video wird aktuell verarbeitet. Dieses Prozess kann je nach Videolänge mehrere Stunden in Anspruch nehmen.",
                        "alert-current-video-failed": "Ihr zuletzt hochgeladenes Video konnte nicht verarbeitet werden. Bitte prüfen Sie unter anderem das Videoformat.",
                        "alert-current-video-ready": "Das Video wurde erfolgreich hochgeladen und steht zum Abruf bereit.",
                        "thumbnail-image": "Vorschaubild",
                    },
                },
                summary: {
                    "description": "Vielen Dank, das war es schon fast!<br />Durch den Klick auf \"Aktualisieren\" werden die Änderungen gespeichert.",
                },
            },
            stats: {
                "title": "Eventstatistiken",
                livestats: {
                    cards: {
                      "live_viewers": "Aktive Zuschauer"
                    },
                    chips: {
                      "live": "Live",
                      "notlive": "Geplant"
                    },
                    viewers: {
                      "table-heading": "Aktive Zuschauer",
                      "first_name": "Vorname",
                      "last_name": "Nachname",
                      "company_name": "Firmenbezeichnung"
                    },
                    "title-notlive": "Dieses Event ist nicht gestartet",
                    "message-notlive": "Sobald dieses Event gestartet ist können Sie hier die Live-Statistiken einsehen.",
                    "title": "Live-Statistiken",
                    "table-title": "Zeitverlauf",
                    "last-update": "Zuletzt aktualisiert:"
                },
                archive: {
                    viewers: {
                        "table-heading": "Zuschauerhistorie",
                        "first_name": "Vorname",
                        "last_name": "Nachname",
                        "company_name": "Firmenbezeichnung",
                        "watch_time": "Wiedergabezeit (hh:mm:ss)"
                    },
                },
            }
        },
        settings: {
            "title": "Unternehmenseinstellungen",
            "description": "Hier können Sie die Einstellungen Ihres Unternehmens anpassen.",
            "form.email_from": "E-Mail-Absender",
            "form.email_smtpserver": "SMTP-Server",
            "form.email_smtpusername": "SMTP-Benutzername",
            "form.email_smtppassword": "SMTP-Passwort",
            "form.email_smtpport": "SMTP-Port",
            "form.email_smtpencryption": "SMTP-Verschlüsselung",
            "form.email_smtpauthmode": "SMTP-Authentifizierung",
            "form.email_verification_urltemplate": "Template für Verifizierungs-Link",
            "form.email_verification_urltemplate_help": "Erforderliche Bausteine: Benutzer-ID: %USERID% - Verifizierungstoken: %TOKEN% - Benutzersprache: %USERLANGUAGE%",
            "form.email_passwordreset_urltemplate": "Template für Passwort-Reset-Link",
            "form.email_passwordreset_urltemplate_help": "Erforderliche Bausteine: Benutzer-ID: %USERID% - Verifizierungstoken: %TOKEN%",
            "form.email_transportenabled": "Versand über eigenen SMTP-Server aktivieren.",
            "form.registration_enabled": "Registierung für neue Benutzer erlauben",
            "form.registration_verificationmethod": "Bestätigungsmethode für neue Benutzer",
            "form.registration_verificationmethod-help": "Diese Einstellung ist nur aktiv, sofern die Registrierung aktiviert ist",
            "button.save": "Änderungen speichern",
            "button.mailtest": "E-Mail-Versand testen",
            "notifier-success": "Die Änderungen wurden erfolgreich gespeichert.",
            "notifier-error": "Die Änderungen konnten nicht gespeichert werden.",
            "notifier-configerror": "Die Änderungen konnten nicht gespeichert werden, da die Validierung fehlgeschlagen ist.",
            "notifier-loading-error": "Die Daten konnten nicht geladen werden.",
            "notifier-testmail-success": "Die Test-E-Mail wurde erfolgreich versandt.",
            "notifier-testmail-error": "Die Test-E-Mail konnte nicht versandt werden. Bitte prüfen Sie die Einstellungen.",
            "notifier-testmail-configerror": "Der Test kann nicht durchgeführt werden, da Sie den Versand über einen eigenen Server nicht aktiviert haben.",
            email: {
                "form.type": "E-Mail Typ",
                "form.subject": "Betreff",
                "form.content_html": "HTML-Inhalt",
                "form.content_text": "Text-Inhalt",
                "form.language": "Sprache",
                "form.active": "Aktiv",
                "form.type-userverification": "Account-Verifizierung",
                "form.type-eventregistration": "Bestätigung Eventregistrierung",
                "form.type-eventreminder": "Erinnerung an Event für angemeldeten Benutzer",
                "form.type-passwordreset": "Passwort zurücksetzen",
                list: {
                    "title": "E-Mail-Templates",
                    "table.heading": "Erstellte E-Mail-Templates",
                    "table.id": "Interne ID",
                    "table.type": "Typ",
                    "table.language": "Sprache",
                    "table.state": "Status",
                    "table.state-active": "Aktiv",
                    "table.state-inactive": "Inaktiv",
                    "type.event_registration": "Bestätigung Eventregistrierung",
                    "type.event_reminder": "Event-Erinnerung",
                    "type.user_verification": "Account-Verifizierung",
                    "type.user_password_reset": "Passwort zurücksetzen",
                    "button.new": "Neues Template",
                    "confirm.title": "E-Mail Template löschen?",
                    "confirm.description": "Sind Sie sicher, dass Sie das E-Mail Template löschen möchten?",
                    "notifier.delete-success": "Das Template wurde erfolgreich gelöscht.",
                    "notifier.delete-failure": "Das Template konnte nicht gelöscht werden.",
                },
                create: {
                    "title": "E-Mail Template erstellen",
                    "heading": "Neues E-Mail Template",
                    "notifier-success": "Das E-Mail Template wurde erfolgreich angelegt.",
                    "notifier-failure": "Das E-Mail Template konnte nicht erstellt werden."
                },
                edit: {
                    "title": "E-Mail Template bearbeiten",
                    "heading": "Template bearbeiten",
                    "notifier.success": "Das E-Mail Template wurde aktualisiert.",
                    "notifier.failure": "Das E-Mail Template konnte nicht aktualisiert werden.",
                    "notifier.loading-failure": "Das E-Mail Template konnte nicht geladen werden.",
                }
            }
        }
    },
    components: {
        dataTable: {
            "rowsPerPage": "Einträge pro Seite",
            "rangeSeparator": "von",
            "noData": "Keine Einträge vorhanden.",
            "switchDenseLayout": "Kompaktes Layout",
            "searchBox": "Suchen...",
            "searchActive": "Suchfilter aktiv"
        }
    }
};
