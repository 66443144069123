import React from 'react';
import Template from "../../Base/Template";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Form from "./Form";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Link as RouterLink} from "react-router-dom";
import {Button} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backButton: {
        marginBottom: theme.spacing(2),
    },
}));

const AdminUserCreate = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Template title={t('pages:user:create:title')}>
            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    size={"small"}
                    className={classes.backButton}
                    startIcon={<ArrowBackIcon />}
                    component={RouterLink}
                    to={"/admin_users"}
                >
                    {t('globals:buttons:backtooverview')}
                </Button>
                <Paper className={classes.paper}>
                    <Form />
                </Paper>
            </Grid>
        </Template>
    );
};

export default AdminUserCreate;
