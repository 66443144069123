import {FETCH_USERS, FETCH_USERS_FAILURE, FETCH_USERS_SUCCESS} from "../types/usersTypes";


const initialState = {
    loading: false,
    error: {},
    users: [],
};

const usersReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_USERS:
            return {
                ...state,
                users: [],
                loading: true,
            };
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.users,
                error: {},
            };
        case FETCH_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                users: [],
                error: {
                    code: action.errCode,
                    message: action.errMsg,
                }
            };
        default:
            return state;
    }
}

export default usersReducer;
