import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {
    Button,
} from "@material-ui/core";
import MailIcon from '@material-ui/icons/Mail';
import {useSnackbar} from "notistack";
import Api from "../../utils/Api";
import {getTenant, getToken} from "../../helper/AuthHelper";

const useStyles = makeStyles((theme) => ({
    button: {
        maxWidth: '300px'
    }
}));

const TestMail = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [testPending, setTestPending] = useState(false);

    const handleTestClicked = async (e) => {
        e.preventDefault();

        setTestPending(true);
        const testSuccess = await doTest();
        setTestPending(false);

        switch(testSuccess){
            case "success":
                enqueueSnackbar(t('pages:settings:notifier-testmail-success'), {variant: 'success'});
                break;
            case "failure":
                enqueueSnackbar(t('pages:settings:notifier-testmail-error'), {variant: 'error'});
                break;
            case "configuration_failure":
                enqueueSnackbar(t('pages:settings:notifier-testmail-configerror'), {variant: 'warning'});
                break;
            default:
                console.debug("Encountered unknown test state.");
                break;
        }
    }

    const doTest = async () => {
        try {
            const response = await Api.post(`/v2/tenants/${getTenant().id}/mail_test`, {}, {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            });
            if(response.status === 200 && response.data?.status === "success") {
                return "success";
            }
            return "failure";
        }
        catch(e) {
            return "configuration_failure";
        }
    };

    return (
        <Button
            variant="contained"
            size={"small"}
            startIcon={<MailIcon />}
            className={classes.button}
            onClick={handleTestClicked}
            disabled={testPending}
        >
            {t('pages:settings:button.mailtest')}
        </Button>
    );
};

export default TestMail;