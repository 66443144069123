import React, {useEffect, useState} from 'react';
import Template from "../../Base/Template";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {Trans, useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {fetchUsers} from "../../../store/actions/usersActions";
import {Skeleton} from "@material-ui/lab";
import UserListTable from "./UserListTable";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from '@material-ui/icons/GetApp';
import {Link as RouterLink} from "react-router-dom";
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import {baseUrl} from "../../../utils/Api";
import {getToken} from "../../../helper/AuthHelper";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginBottom: theme.spacing(2),
    }
}));

const UserList = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    /* Export modal handling */
    const [exportOpen, setExportOpen] = useState(false);
    const [exportFileType, setExportFileType] = useState("csv");
    const handleOpen = (filetype) => {
        setExportOpen(true);
        setExportFileType(filetype);
    };
    const handleClose = () => {
        setExportOpen(false);
    }
    const handleExport = async () => {
        window.location.href = `${baseUrl}/v2/users/export?filetype=${exportFileType}&authtoken=${getToken()}`;
        setExportOpen(false);
    }

    useEffect(() => {
        props.fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Template title={t('pages:user:list:title')}>
            <Grid item xs={12}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                        variant="contained"
                        color={"primary"}
                        className={classes.button}
                        startIcon={<AddIcon />}
                        component={RouterLink}
                        to={"/users/create"}
                    >
                        {t('globals:buttons:new')}
                    </Button>
                    <div>
                        <ButtonGroup
                            color="primary"
                            variant={"text"}
                            size={"small"}
                            style={{height: '20px'}}
                        >
                            <Button color={"default"} disabled><GetAppIcon fontSize={"small"} />Export</Button>
                            <Button onClick={() => {handleOpen("csv")}}>CSV</Button>
                            <Button onClick={() => {handleOpen("xlsx")}}>XLSX</Button>
                        </ButtonGroup>
                    </div>
                </div>
                <Paper className={classes.paper}>
                    {props.userList.loading && !props.userList.error.code ?
                        <Skeleton height={"40px"} />
                        :
                        !props.userList.loading && !props.userList.error.code ?
                            <UserListTable />
                            :
                            <p>Error!</p>
                    }
                </Paper>
            </Grid>
            <Dialog
                open={exportOpen}
                onClose={handleClose}
            >
                <DialogTitle>{t('pages:user:list:export:dialog-title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans i18nKey={"pages:user:list:export:dialog-description"} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t('globals:buttons:cancel')}
                    </Button>
                    <Button onClick={handleExport} color="primary" variant={"contained"} autoFocus>
                        {t('pages:user:list:export:dialog-export-button')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Template>
    );
};

const mapStateToProps = (state) => {
    return {
        userList: state.users,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsers: () => dispatch(fetchUsers())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
