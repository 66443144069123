import React, {useEffect} from 'react';
import Template from "../../Base/Template";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Skeleton} from "@material-ui/lab";
import {fetchTemplates} from "../../../store/actions/emailTemplatesActions";
import EmailListTable from "./EmailListTable";
import AddIcon from "@material-ui/icons/Add";
import {Link as RouterLink} from "react-router-dom";
import {Button} from "@material-ui/core";
import {ConfirmProvider} from "material-ui-confirm";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginBottom: theme.spacing(2),
    }
}));

const EmailList = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    useEffect(() => {
        props.fetchTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Template title={t('pages:settings:email:list:title')}>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color={"primary"}
                    className={classes.button}
                    startIcon={<AddIcon />}
                    component={RouterLink}
                    to={"/settings/email-templates/create"}
                >
                    {t('pages:settings:email:list:button.new')}
                </Button>
                <Paper className={classes.paper}>
                    {props.templatesList.loading && !props.templatesList.error.code ?
                        <Skeleton height={"40px"} />
                        :
                        !props.templatesList.loading && !props.templatesList.error.code ?
                            <ConfirmProvider>
                                <EmailListTable />
                            </ConfirmProvider>
                            :
                            <p>Error!</p>
                    }
                </Paper>
            </Grid>
        </Template>
    );
};

const mapStateToProps = (state) => {
    return {
        templatesList: state.emailTemplates
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTemplates: () => dispatch(fetchTemplates())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailList);