import React, {useEffect} from 'react';
import Template from "../../Base/Template";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import { useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {fetchUsers} from "../../../store/actions/usersActions";
import {Skeleton} from "@material-ui/lab";
import UserListTable from "./AdminUserListTable";
import AddIcon from "@material-ui/icons/Add";
import {Link as RouterLink} from "react-router-dom";
import {
    Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginBottom: theme.spacing(2),
    }
}));

const AdminUserList = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    useEffect(() => {
        props.fetchUsers("admin");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Template title={t('pages:admin_user:list:title')}>
            <Grid item xs={12}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                        variant="contained"
                        color={"primary"}
                        className={classes.button}
                        startIcon={<AddIcon />}
                        component={RouterLink}
                        to={"/admin_users/create"}
                    >
                        {t('globals:buttons:new')}
                    </Button>
                </div>
                <Paper className={classes.paper}>
                    {props.userList.loading && !props.userList.error.code ?
                        <Skeleton height={"40px"} />
                        :
                        !props.userList.loading && !props.userList.error.code ?
                            <UserListTable />
                            :
                            <p>Error!</p>
                    }
                </Paper>
            </Grid>
        </Template>
    );
};

const mapStateToProps = (state) => {
    return {
        userList: state.users,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsers: (type) => dispatch(fetchUsers(type))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserList);
