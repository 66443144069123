import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, LinearProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Alert} from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        marginTop: theme.spacing(5)
    },
    alert: {
        marginTop: theme.spacing(4),
    }
}));

const PageLoading = () => {
    const classes = useStyles();

    const [loadingTooLong, isLoadingTooLong] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
           isLoadingTooLong(true);
        }, 4000);

        return(() => {
            clearTimeout(timeout);
        });

    }, []);

    return (
        <div className={classes.content}>
            <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12} md={7} lg={6}>
                    <Typography component={"div"} align={"center"} className={classes.title}>Lade Anwendung... <LinearProgress style={{height: 10, borderRadius: 5, marginTop: 10}} /></Typography>
                    {loadingTooLong &&
                    <Alert className={classes.alert} severity={"warning"} >
                        <strong>Die Anwendung lädt ungewöhnlich lange.</strong><br />
                        Falls Sie diese Meldung häufiger sehen wenden Sie sich bitte an unseren Support.
                    </Alert>
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default PageLoading;
