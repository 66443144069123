import {
    FETCH_EVENT,
    FETCH_EVENT_FAILURE,
    FETCH_EVENT_INGEST, FETCH_EVENT_INGEST_FAILURE,
    FETCH_EVENT_INGEST_SUCCESS, FETCH_EVENT_INITIAL,
    FETCH_EVENT_SUCCESS
} from "../types/singleEventTypes";

const initialState = {
    loading: true,
    error: {},
    event: {},
    ingest: {},
};

const singleEventReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_EVENT_INITIAL:
            return {
                ...state,
                event: {},
                loading: true,
            };
        case FETCH_EVENT:
            return {
                ...state,
                loading: true,
            };
        case FETCH_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                event: action.event,
                error: {},
            };
        case FETCH_EVENT_FAILURE:
            return {
                ...state,
                loading: false,
                event: {},
                error: {
                    code: action.errCode,
                    message: action.errMsg,
                }
            };
        case FETCH_EVENT_INGEST:
            return {
                ...state,
                ingest: {},
            };
        case FETCH_EVENT_INGEST_SUCCESS:
            return {
                ...state,
                ingest: action.ingest,
            };
        case FETCH_EVENT_INGEST_FAILURE:
            return {
                ...state,
                ingest: {},
            };
        default:
            return state;
    }
}

export default singleEventReducer;
