import API from "../../utils/Api";

import {FETCH_USERS, FETCH_USERS_FAILURE, FETCH_USERS_SUCCESS} from "../types/usersTypes";
import {getToken} from "../../helper/AuthHelper";

export const fetchUsers = (type = "user") => {

    let queryString = "";
    if(type === "admin") {
        queryString = "?list_admins"
    }

    return (dispatch) => {
        dispatch(fetchUsersStart());
        API.get(`/v2/users${queryString}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
        })
            .then((response) => {
                dispatch(fetchUsersSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchUsersFailure(error));
            })
    }
}

export const fetchUsersStart = () => {
    return {
        type: FETCH_USERS
    };
}


export const fetchUsersSuccess = (data) => {
    return {
        type: FETCH_USERS_SUCCESS,
        users: data.users,
    }
};

export const fetchUsersFailure = (error) => {
    return {
        type: FETCH_USERS_FAILURE,
        errMsg: error.message,
        errCode: error.response ? error.response.status ?? -1 : -1,
    }
}

