import React from "react";
import {useTranslation} from "react-i18next";

import DataTable from "../../../components/DataTable";

const LivestreamLiveViewers = ({viewers}) => {
  const {t} = useTranslation();


  const columns = [
    {
      selector: row => row['user']['first_name'],
      name: t('pages:event:stats:livestats:viewers:first_name'),
      sortable: true
    },
    {
      selector: row => row['user']['last_name'],
      name: t('pages:event:stats:livestats:viewers:last_name'),
      sortable: true
    },
    {
      selector: row => row['user']['company_name'],
      name: t('pages:event:stats:livestats:viewers:company_name'),
      sortable: true
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <DataTable
        title={t('pages:event:stats:livestats:viewers:table-heading')}
        columns={columns}
        data={viewers}
        denseLayout={true}
        showLayoutSwitch={false}
        searchFields={['first_name', 'last_name', 'company_name']}
      />
    </div>
  );
};

export default LivestreamLiveViewers;
