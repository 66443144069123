import API from "../../utils/Api";

import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGIN_USER_TENANT_REQUIRED, LOGIN_RESET
} from '../types/loginTypes';
import {saveTenant, saveToken} from "../../helper/AuthHelper";

export const login = (email, password, tenant_id = undefined) => {

    return (dispatch) => {
        dispatch(loginStart());
        API.post(`/v2/auth/login`, {
            username: email,
            password: password,
            tenant_id: tenant_id
        })
            .then((response) => {
                if(response.data.status === "tenant_required"){
                    dispatch(loginTenantRequired(response.data));
                }
                else if(response.data?.user.roles.includes('ROLE_SUPERADMIN') !== true && response.data?.user.roles.includes('ROLE_ADMIN') !== true) {
                    dispatch(loginFailure({
                        message: "User is not an administrative user.",
                        response: { status: 403 }
                    }));
                    console.debug("User is not an administrative user. Denying access.");
                }
                else {
                    saveToken(response.data.auth.token);
                    saveTenant(response.data.tenant);
                    dispatch(loginSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(loginFailure(error));
            })
    }
}

export const oauthLogin = (state, session_state, code, redirect_uri = undefined) => {

    return (dispatch) => {
        dispatch(loginStart());
        API.get(`/v2/auth/oauth_check`, {
            params: {
                state: state,
                session_state: session_state,
                code: code,
                redirect_uri: redirect_uri
            }
        })
            .then((response) => {
                if(response.data.status === "tenant_required"){
                    dispatch(loginTenantRequired(response.data));
                }
                else {
                    saveToken(response.data.auth.token);
                    saveTenant(response.data.tenant);
                    dispatch(loginSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(loginFailure(error));
            })
    }
}

export const oauthTenantLogin = (verify_token, tenant_id) => {

    return (dispatch) => {
        dispatch(loginStart());
        API.post(`/v2/auth/oauth_tenant`, {
            verify_token: verify_token,
            tenant_id: tenant_id
        })
            .then((response) => {
                saveToken(response.data.auth.token);
                saveTenant(response.data.tenant);
                dispatch(loginSuccess(response.data));
            })
            .catch((error) => {
                dispatch(loginFailure(error));
            })
    }
}

export const loginStart = () => {
    return {
        type: LOGIN_USER
    };
}


export const loginSuccess = (data) => {
    return {
        type: LOGIN_USER_SUCCESS,
        user: data.user,
        tenants: data.tenant,
    }
};

export const loginTenantRequired = (data) => {
    return {
        type: LOGIN_USER_TENANT_REQUIRED,
        user: null,
        tenants: data.tenants,
        verify_token: data?.verify_token
    }
};

export const loginFailure = (error) => {
    return {
        type: LOGIN_USER_FAILURE,
        errMsg: error.message,
        errCode: error.response ? error.response.status ?? -1 : -1,
    }
}

export const loginReset = () => {
    return {
        type: LOGIN_RESET
    };
}
