import API from "../../utils/Api";

import {getTenant, getToken} from "../../helper/AuthHelper";
import {
    FETCH_SINGLE_EMAIL_TEMPLATE,
    FETCH_SINGLE_EMAIL_TEMPLATE_FAILURE,
    FETCH_SINGLE_EMAIL_TEMPLATE_SUCCESS,
    UPDATE_SINGLE_EMAIL_TEMPLATE,
    UPDATE_SINGLE_EMAIL_TEMPLATE_FAILURE,
    UPDATE_SINGLE_EMAIL_TEMPLATE_SUCCESS
} from "../types/singleEmailTemplateTypes";

export const fetchTemplate = (id) => {

    const tenantId = getTenant().id;

    return (dispatch) => {
        dispatch(fetchTemplateStart());
        API.get(`/v2/tenants/${tenantId}/email_templates/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
            .then((response) => {
                dispatch(fetchTemplateSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchTemplateFailure(error));
            })
    }
}

export const fetchTemplateStart = () => {
    return {
        type: FETCH_SINGLE_EMAIL_TEMPLATE
    };
}


export const fetchTemplateSuccess = (data) => {
    return {
        type: FETCH_SINGLE_EMAIL_TEMPLATE_SUCCESS,
        template: data.template,
    }
};

export const fetchTemplateFailure = (error) => {
    return {
        type: FETCH_SINGLE_EMAIL_TEMPLATE_FAILURE,
        errMsg: error.message,
        errCode: error.response ? error.response.status ?? -1 : -1,
    }
}

export const updateTemplate = (id, data) => {

    const tenantId = getTenant().id;

    return (dispatch) => {
        dispatch(updateTemplateStart());
        API.patch(`/v2/tenants/${tenantId}/email_templates/${id}`, data,{
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
            .then((response) => {
                dispatch(updateTemplateSuccess(response.data));
            })
            .catch((error) => {
                dispatch(updateTemplateFailure(error));
            })
    }
}

export const updateTemplateStart = () => {
    return {
        type: UPDATE_SINGLE_EMAIL_TEMPLATE
    };
}


export const updateTemplateSuccess = (data) => {
    return {
        type: UPDATE_SINGLE_EMAIL_TEMPLATE_SUCCESS,
        template: data.template,
    }
};

export const updateTemplateFailure = (error) => {
    return {
        type: UPDATE_SINGLE_EMAIL_TEMPLATE_FAILURE,
        errMsg: error.message,
        errCode: error.response ? error.response.status ?? -1 : -1,
    }
}

