import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {
    Avatar,
    CssBaseline,
    Link,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LockIcon from '@material-ui/icons/Lock';

import background from "../../assets/login-background.jpg";
import logo from "../../assets/logo.svg";
import {Alert} from "@material-ui/lab";
import Api from "../../utils/Api";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'left center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    formControl: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        minWidth: 120,
    },
    formInputLabel: {
        marginTop: '-10px'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        maxWidth: '120px',
        marginBottom: '30px'
    },
    alert: {
        width: '100%'
    }
}));

const Footer = () => {
    const {t} = useTranslation();

    return (
        <Grid container style={{marginTop: '35px'}}>
            <Grid item>
                <Link href={"https://www.joy-event-media.de/impressum/"} variant="body2" target={"_blank"} rel={"noopener noreferrer"}>
                    {t('pages:login:legallinks')}
                </Link>
            </Grid>
        </Grid>
    )
}

const Verify = () => {
    const classes = useStyles();

    const urlParams = new URLSearchParams(window.location.search);
    const [state, setState] = useState(null);

    useEffect(() => {
        async function check() {
            if(urlParams.has("uid") && urlParams.has("token")) {
                try {
                    const response = await Api.post(`/v2/users/${urlParams.get("uid")}/verify_token`, {
                        token: urlParams.get("token")
                    });
                    if(response.status === 200) {
                        console.debug("Verification was successful.");
                        setState(true);
                        return;
                    }
                }
                catch(e) {
                    console.debug("Verification was not successful.", e);
                }
            }
            setState(false);
        }
        check().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <img src={logo} className={classes.logo} alt={"Logo"} />
                    <Avatar className={classes.avatar}>
                        <LockIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" style={{marginBottom: '20px'}}>
                        Account bestätigen
                    </Typography>

                    {state === false &&
                    <Alert severity={"warning"} className={classes.alert}>
                        <b>Aktivierung fehlgeschlagen</b><br/>
                        Ihr Account konnte nicht aktiviert werden. Möglicherweise wurde er bereits aktiviert oder der Link ist abgelaufen.
                    </Alert>
                    }
                    {state === true &&
                    <Alert severity={"success"} className={classes.alert}>
                        <b>Aktivierung erfolgreich</b><br/>
                        Ihr Account wurde erfolgreich aktiviert. Sie können Sie sich jetzt mit Ihren Anmeldedaten einloggen.
                    </Alert>
                    }
                    {state === null &&
                    <Alert severity={"info"} className={classes.alert}>
                        <b>Aktivierung wird geprüft</b><br/>
                        Bitte warten Sie einen Moment, Ihre Aktivierungsanfrage wird geprüft...
                    </Alert>
                    }

                    <Footer />
                </div>
            </Grid>
        </Grid>
    );
};

export default Verify;