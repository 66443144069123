import React, {useEffect} from 'react';
import {Redirect, Route} from "react-router-dom";
import {useAuth} from "./AuthHelper";

const ProtectedRoute = ({ component: Component, path, ...rest }) => {
    const { check, loggedIn } = useAuth();

    useEffect(() => {
        const fn = async () => {
            await check();
        };
        fn().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn, path]);

    const render = props =>
        loggedIn === true ? <Component {...props} /> : <Redirect to={"/login"} />;

    return <Route path={path} render={render} {...rest} />;
};
export default ProtectedRoute;