import API from "../../utils/Api";

import {FETCH_EVENTS, FETCH_EVENTS_FAILURE, FETCH_EVENTS_SUCCESS} from "../types/eventsTypes";
import {getToken} from "../../helper/AuthHelper";

export const fetchEvents = () => {

    return (dispatch) => {
        dispatch(fetchEventsStart());
        API.get(`/v2/events`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
            .then((response) => {
                dispatch(fetchEventsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchEventsFailure(error));
            })
    }
}

export const fetchEventsStart = () => {
    return {
        type: FETCH_EVENTS
    };
}


export const fetchEventsSuccess = (data) => {
    return {
        type: FETCH_EVENTS_SUCCESS,
        events: data.events,
    }
};

export const fetchEventsFailure = (error) => {
    return {
        type: FETCH_EVENTS_FAILURE,
        errMsg: error.message,
        errCode: error.response ? error.response.status ?? -1 : -1,
    }
}

