import React, {useEffect} from 'react';
import Template from "../../Base/Template";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Skeleton} from "@material-ui/lab";
import {fetchSingleUser} from "../../../store/actions/singleUserActions";
import Form from "./Form";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Link as RouterLink} from "react-router-dom";
import {Button} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backButton: {
        marginBottom: theme.spacing(2),
    },
}));

const UserEdit = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    let {id} = props.match.params;

    useEffect(() => {
        props.fetchUser(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <Template title={t('pages:user:edit:title')}>
            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    size={"small"}
                    className={classes.backButton}
                    startIcon={<ArrowBackIcon />}
                    component={RouterLink}
                    to={"/users"}
                >
                    {t('globals:buttons:backtooverview')}
                </Button>
                <Paper className={classes.paper}>
                    {props.user.loading && !props.user.error.code ?
                        <Skeleton height={"40px"} />
                        :
                        !props.user.loading && !props.user.error.code ?
                            <Form user={props.user.user} />
                            :
                            <p>Error!</p>
                    }
                </Paper>
            </Grid>
        </Template>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.singleUser,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUser: (id) => dispatch(fetchSingleUser(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);