import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {useTranslation} from "react-i18next";


const LanguageSelect = ({labelTranslationKey, value, multiple = false, onChange, className = null}) => {
    const {t} = useTranslation();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    return (
        <FormControl className={className}>
            <InputLabel id="language-label">{t(labelTranslationKey)}</InputLabel>
            <Select
                labelId="language-label"
                name={"language"}
                value={value}
                onChange={onChange}
                multiple={multiple}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 250
                    }
                  },
                  variant: "menu",
                  getContentAnchorEl: null
                }}
            >
                <MenuItem value={"de"}>{t('globals:languages:german')}</MenuItem>
                <MenuItem value={"en"}>{t('globals:languages:english')}</MenuItem>
                <MenuItem value={"fr"}>{t('globals:languages:french')}</MenuItem>
                <MenuItem value={"it"}>{t('globals:languages:italian')}</MenuItem>
                <MenuItem value={"pl"}>{t('globals:languages:polish')}</MenuItem>
                <MenuItem value={"ru"}>{t('globals:languages:russian')}</MenuItem>
                <MenuItem value={"es"}>{t('globals:languages:spanish')}</MenuItem>
                <MenuItem value={"tr"}>{t('globals:languages:turkish')}</MenuItem>
            </Select>
        </FormControl>
    );
}

export default LanguageSelect;
