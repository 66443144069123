import {
    FETCH_EVENT_REGISTRATIONS,
    FETCH_EVENT_REGISTRATIONS_FAILURE,
    FETCH_EVENT_REGISTRATIONS_SUCCESS
} from "../types/eventRegistrationTypes";

const initialState = {
    loading: true,
    error: {},
    registrations: [],
};

const eventRegistrationsReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_EVENT_REGISTRATIONS:
            return {
                ...state,
                registrations: [],
                loading: true,
            };

        case FETCH_EVENT_REGISTRATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                registrations: action.registrations,
                error: {},
            };
        case FETCH_EVENT_REGISTRATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                registrations: [],
                error: {
                    code: action.errCode,
                    message: action.errMsg,
                }
            };
        default:
            return state;
    }
}

export default eventRegistrationsReducer;
