import API from "../../utils/Api";

import {getTenant, getToken} from "../../helper/AuthHelper";
import {
    FETCH_SETTINGS,
    FETCH_SETTINGS_FAILURE,
    FETCH_SETTINGS_SUCCESS,
    UPDATE_SETTINGS, UPDATE_SETTINGS_FAILURE,
    UPDATE_SETTINGS_SUCCESS
} from "../types/settingsTypes";

export const fetchSettings = () => {
    const tenantId = getTenant()?.id;

    return (dispatch) => {
        dispatch(fetchSettingsStart());
        API.get(`/v2/tenants/${tenantId}/settings`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
            .then((response) => {
                dispatch(fetchSettingsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchSettingsFailure(error));
            })
    }
}

export const fetchSettingsStart = () => {
    return {
        type: FETCH_SETTINGS
    };
}


export const fetchSettingsSuccess = (data) => {
    return {
        type: FETCH_SETTINGS_SUCCESS,
        settings: data.settings,
    }
};

export const fetchSettingsFailure = (error) => {
    return {
        type: FETCH_SETTINGS_FAILURE,
        errMsg: error.message,
        errCode: error.response ? error.response.status ?? -1 : -1,
    }
}


export const updateSettings = (data) => {
    const tenantId = getTenant()?.id;

    return (dispatch) => {
        dispatch(updateSettingsStart());
        API.patch(`/v2/tenants/${tenantId}/settings`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
            .then((response) => {
                dispatch(updateSettingsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(updateSettingsFailure(error));
            })
    }
}

export const updateSettingsStart = () => {
    return {
        type: UPDATE_SETTINGS
    };
}


export const updateSettingsSuccess = (data) => {
    return {
        type: UPDATE_SETTINGS_SUCCESS,
        settings: data.settings,
    }
};

export const updateSettingsFailure = (error) => {
    return {
        type: UPDATE_SETTINGS_FAILURE,
        errMsg: error.message,
        errCode: error.response ? error.response.status ?? -1 : -1,
    }
}