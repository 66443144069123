import React, {useEffect, useState} from "react";
import {Divider, FormHelperText, InputAdornment, TextField, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {useDebouncedEffect} from "../../../../../helper/useDebouncedEffect";
import Api from "../../../../../utils/Api";
import slugify from 'slugify';
import {DateTime} from "luxon";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main
    },
    fieldInput: {
        marginTop: '10px'
    },
    divider: {
        marginTop: '5px',
        marginBottom: '10px',
    }
}));

const Basic = ({setBasic, values}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const [state, setState] = useState(values);

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        const prefix = DateTime.now().toFormat("yyyyMMddHHmmss");
        const slug = slugify(prefix + "-" + state.title, {
            lower: true,
            locale: 'de',
            strict: true,
            replacement: '-'
        }).substring(0,98);
        setState({
            ...state,
            slug: slug
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.title]);

    /* Make sure the slug field is url-friendly */
    useDebouncedEffect(() => {
        if(state?.slug !== undefined && state.slug !== "") {
            setState({
                ...state,
                slug: slugify(state.slug, {
                    lower: true,
                    locale: 'de',
                    strict: true,
                    replacement: '-'
                })
            })
        }
    }, [state.slug], 700);

    /* Check if the slug is available */
    const [slugAvailable, setSlugAvailable] = useState(null);
    useDebouncedEffect(() => {
        if(state?.slug !== undefined && state.slug !== "") {
            const slug = state.slug.trim();
            Api.get(`/v2/events/by_slug/${slug}`)
                .then((r) => {
                    console.debug("Slug is taken.");
                    setSlugAvailable(false);
                })
                .catch((e) => {
                   if(e.response?.status === 404) {
                        console.debug("Slug is available.");
                        setSlugAvailable(true);
                    }
                })
            ;
        }
    }, [state.slug], 750);

    useDebouncedEffect(() => {
        setBasic({
            completed: isCompleted(),
            values: state,
        });
    }, [state], 200);

    const isCompleted = () => {
        let empty = false;
        for(const field of fields){
            if(field.name === "description") {continue;}
            if(state[field.name] === undefined || state[field.name] === null || state[field.name] === "") {
                console.debug(field.name, "not completed");
                empty = true;
                break;
            }
            else {
                console.debug(field.name, "is completed");
            }
        }

        if(slugAvailable === false) {
            console.debug("Slug is taken, cannot complete.");
            empty = true;
        }

        return !empty;
    };

    const fields = [
        {
            label: t('pages:event:create:information:basic.form.title'),
            name: "title",
        },
        {
            label: t('pages:event:create:information:basic.form.description'),
            name: "description",
            help: t('pages:event:create:information:basic.form.description-help'),
            multiline: true,
        }
    ]

    return (
        <div>
            <Typography variant={"h4"} className={classes.title}>{t('pages:event:create:information:basic.title')}</Typography>
            <Divider className={classes.divider} />
            {fields.map((field, index) => {
                return (
                    <React.Fragment key={index}>
                        <TextField
                            name={field.name}
                            label={field.label}
                            fullWidth
                            error={field.name === "slug" ? slugAvailable === false : false }
                            size={"small"}
                            onChange={handleChange}
                            className={classes.fieldInput}
                            multiline={field.multiline ? field.multiline : false}
                            value={state[field.name] ?? ""}
                            rows={7}
                            InputProps={{
                                startAdornment: field.name === "slug" ? <InputAdornment position="start">{t('pages:event:create:information:basic.form.slug-adornment')}</InputAdornment> : <></>,
                            }}
                        />
                        {field.help && <FormHelperText error={field.name === "slug" ? slugAvailable === false : false }>{field.name === "slug" && slugAvailable === false ? t('pages:event:create:information:basic:form.slugtaken') : field.help}</FormHelperText>}
                    </React.Fragment>
                )
            })}
        </div>
    );
}

export default Basic;
