import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {LANGUAGE_SWITCHER_STORAGE_KEY} from "../views/Base/LanguageSwitcher";
import {APP_LANGUAGE_DE} from "./de";
import {APP_LANGUAGE_EN} from "./en";

const resources = {
    de: APP_LANGUAGE_DE,
    en: APP_LANGUAGE_EN,
};

export const languages = ["de", "en"];

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: sessionStorage.getItem(LANGUAGE_SWITCHER_STORAGE_KEY),
        fallbackLng: ['en'],
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        defaultNS: "app",
        ns: [
            'app',
            'ns'
        ],
        debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
        react: {
            transSupportBasicHtmlNodes: true,
        }
    }).then(r => {
        console.debug("i18n initialized successfully.");
    })
    .catch(e => {
        console.debug("i18n failed to initialize.");
    })
;

export default i18n;
