import React, {useEffect} from 'react';
import Template from "../../Base/Template";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Skeleton} from "@material-ui/lab";
import EventListTable from "./EventListTable";
import {fetchEvents} from "../../../store/actions/eventsActions";
import {Button} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import {Link as RouterLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginBottom: theme.spacing(2),
    }
}));

const EventList = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    useEffect(() => {
        props.fetchEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Template title={t('pages:event:list:title')}>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color={"primary"}
                    className={classes.button}
                    startIcon={<AddIcon />}
                    component={RouterLink}
                    to={"/events/create"}
                >
                    {t('pages:event:list:button.new')}
                </Button>
                <Paper className={classes.paper}>
                    {props.eventList.loading && !props.eventList.error.code ?
                        <Skeleton height={"40px"} />
                        :
                        !props.eventList.loading && !props.eventList.error.code ?
                            <EventListTable />
                            :
                            <p>Error!</p>
                    }
                </Paper>
            </Grid>
        </Template>
    );
};

const mapStateToProps = (state) => {
    return {
        eventList: state.events,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchEvents: () => dispatch(fetchEvents())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);