import {Link} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

import EditIcon from '@material-ui/icons/Edit';
import {Link as RouterLink} from "react-router-dom";
import DataTable from "../../../components/DataTable";

const UserListTable = (props) => {
    const {t} = useTranslation();

    const columns = [
        {
            selector: row => row['email'],
            name: t('pages:user:list:table.email'),
            sortable: true
        },
        {
            selector: row => row['first_name'],
            name: t('pages:user:list:table.firstname'),
            sortable: true,
        },
        {
            selector: row => row['last_name'],
            name: t('pages:user:list:table.lastname'),
            sortable: true,
        },
        {
            selector: row => row['language'],
            name: t('pages:user:list:table.language'),
            sortable: true,
            cell: ({language}) => {
                return t(`globals:languages:${language}`);
            }
        },
        {
            cell: ({active}) => active === true ? t('pages:user:list:table.state-active') : t('pages:user:list:table.state-inactive'),
            name: t('pages:user:list:table.state'),
            sortable: true,
        },
        {
            cell: ({id}) => <Link component={RouterLink} to={`/users/${id}`} color={"textPrimary"}><EditIcon /></Link>,
            button: true,
        },
    ];

    return (
        <div style={{ width: '100%' }}>
            <DataTable
                title={t('pages:user:list:table.heading')}
                columns={columns}
                data={props.userList.users}
                denseLayout={true}
                searchFields={['email', 'first_name', 'last_name']}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userList: state.users,
    };
}

export default connect(mapStateToProps)(UserListTable);
