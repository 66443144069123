import React, {useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {Trans, useTranslation} from "react-i18next";
import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Step,
    StepLabel,
    Stepper, TextField
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import GroupIcon from '@material-ui/icons/Group';
import {Link as RouterLink, Redirect} from "react-router-dom";
import Information from "./Steps/Information/Information";
import Summary from "./Steps/Summary";
import {useDebouncedEffect} from "../../../helper/useDebouncedEffect";
import Api from "../../../utils/Api";
import {getToken} from "../../../helper/AuthHelper";
import {useSnackbar} from "notistack";
import {DateTime} from "luxon";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';

import {fetchSingleEventIngest} from "../../../store/actions/singleEventActions";
import {connect} from "react-redux";
import IngestDialog from "./IngestDialog";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginBottom: theme.spacing(2),
    },
    stepBox: {
        marginBottom: '30px',
        marginTop: '15px'
    },
    infoStepback: {
        fontStyle: 'italic',
        marginTop: '-10px'
    }
}));

const EventEdit = ({id, data, ingest, fetchIngest}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [originalData] = useState(data);
    const steps = [
        t('pages:event:create:steps.metadata'),
        t('pages:event:create:steps.summary')
    ];
    const [activeStep, setActiveStep] = useState(0);
    const [globalValues, setGlobalValues] = useState({});
    const [mutationState, setMutationState] = useState({
        updated: false,
        error: false,
        id: null,
    });
    const [deleted, setDeleted] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [qbOpen, setQbOpen] = useState(false);


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await Api.patch(`/v2/events/${id}`, {
                title: globalValues?.title,
                slug: globalValues?.slug,
                description: globalValues?.description,
                starts_at: globalValues?.start_date.toISO(),
                ends_at: globalValues?.end_date.toISO(),
                ends_at_hidden: globalValues?.end_date_hidden,
                type: globalValues?.type,
                tags: globalValues?.tags,
                language: globalValues?.language,
                external_url: globalValues?.external_url,
            }, {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            });

            if(response.status === 200) {
                setMutationState({
                    updated: true,
                    error: false,
                    id: response.data.event.id,
                });
                enqueueSnackbar(t('pages:event:edit:notifier-success'), {variant: 'success'});
            }
        }
        catch(e) {
            console.error(e);
            setMutationState({
                updated: false,
                error: true,
                id: null
            });
            enqueueSnackbar(t('pages:event:edit:notifier-error'), {variant: 'error'});
        }

    };

    const [stepState, setStepState] = useState([
        {
            completed: false,
            values: {
                title: data?.title,
                slug: data?.slug,
                description: data?.description,
                start_date: DateTime.fromISO(data?.starts_at),
                end_date: DateTime.fromISO(data?.ends_at),
                end_date_hidden: data?.ends_at_hidden,
                language: data?.language,
                tags: data?.tags,
                auth_type: "user",
                external_url: data?.external_url,
                type: data?.type,
            },
        },
        {
            completed: true,
        },
    ]);
    const handleStepCallback = (stepId, state, values) => {
        let current = stepState;
        current[stepId] = {
            completed: state,
            values: values,
        };
        setStepState([...current]);

        console.debug(stepId, state, values);
    }

    useDebouncedEffect(() => {
        let newGlobalState = {};
        stepState.forEach((state) => {
           newGlobalState = {
               ...newGlobalState,
               ...state.values,
           };
        });
        setGlobalValues(newGlobalState);

        console.log(newGlobalState);
    }, [stepState], 200);

    const getActiveStep = (step) => {
        switch(step) {
            case 0:
                return <Information stepId={step} values={stepState[step].values} originalData={originalData} eventType={data.type} handleCallback={handleStepCallback} />;
            case 1:
                return <Summary stepId={step} globalValues={globalValues} handleCallback={handleStepCallback} mutationState={mutationState} />;
            default:
                return "Unknown step.";
        }
    }

    if(mutationState.updated === true) {
        return <Redirect to={"/events"} />;
    }

    /* Deletion handling */
    const handleOpen = () => {
        setDeleteOpen(true);
    };
    const handleClose = () => {
        setDeleteOpen(false);
    }

    /* Questionbox handling */
    const handleQbOpen = () => {
        setQbOpen(true);
    };
    const handleQbClose = () => {
        setQbOpen(false);
    }


    const handleDelete = async () => {
        try {
            const response = await Api.delete(`/v2/events/${id}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            });
            if(response.status === 200){
                enqueueSnackbar(t('pages:event:edit:notifier-delete-success'),{variant: "success"});
                handleClose();
                setDeleted(true);
            }
        }
        catch(e) {
            console.debug("Deletion of user failed.", e);
            enqueueSnackbar(t('pages:event:edit:notifier-delete-failure'),{variant: "warning"});
        }
    }

    if(deleted === true) {
        return <Redirect to={"/events"} />
    }

    return (
        <Grid item xs={12}>
            <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      startIcon={<ArrowBackIcon />}
                      size={"small"}
                      component={RouterLink}
                      to={"/events"}
                    >
                        {t('globals:buttons:backtooverview')}
                    </Button>
                    {originalData.auth_type === "user" &&
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        className={classes.button}
                        style={{marginLeft: '10px'}}
                        startIcon={<GroupIcon />}
                        size={"small"}
                        component={RouterLink}
                        to={`/events/${id}/registrations`}
                      >
                          {t('pages:event:edit:button.registrations')}
                      </Button>
                    }
                </div>
                <div>
                    {originalData.type === "live" && originalData.auth_type === "user" &&
                      <Button
                        variant="outlined"
                        className={classes.button}
                        startIcon={<QuestionAnswer />}
                        size={"small"}
                        style={{marginRight: '5px'}}
                        onClick={handleQbOpen}
                      >
                          {t('pages:event:edit:button.questionbox-data')}
                      </Button>
                    }
                    {originalData.type === "live" &&
                      <IngestDialog />
                    }
                </div>
            </Box>
            <Paper className={classes.paper}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        return (
                            <Step key={index}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

                <Box className={classes.stepBox}>
                    {getActiveStep(activeStep)}
                </Box>

                <Box display={"flex"} justifyContent={"space-between"}>
                    <div>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                            variant={"outlined"}
                            startIcon={<NavigateBeforeIcon />}
                        >
                            {t('pages:event:button.stepback')}
                        </Button>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            size={"small"}
                            className={classes.button}
                            startIcon={<DeleteForeverIcon />}
                            onClick={handleOpen}
                            style={{marginLeft: '10px'}}
                        >
                            {t('globals:buttons:delete')}
                        </Button>
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={activeStep !== steps.length -1 ? handleNext : handleSubmit}
                        className={classes.button}
                        disabled={stepState[activeStep].completed !== true || mutationState.updated === true}
                        endIcon={activeStep === steps.length - 1 ? <CreditCardIcon /> : <NavigateNextIcon /> }
                    >
                        {activeStep === steps.length - 1 ? t('globals:buttons:save-update') : t('pages:event:button.continue')}
                    </Button>
                </Box>
                {activeStep > 0 && <span className={classes.infoStepback}>{t('pages:event:info.stepback')}</span> }
            </Paper>

            <Dialog
                open={deleteOpen}
                onClose={handleClose}
            >
                <DialogTitle>{t('pages:event:edit:dialog.delete-title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans i18nKey={"pages:event:edit:dialog.delete-description"} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t('globals:buttons:cancel')}
                    </Button>
                    <Button onClick={handleDelete} color="secondary" variant={"contained"} autoFocus>
                        {t('globals:buttons:delete-forever')}
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
              open={qbOpen}
              onClose={handleQbClose}
            >
                <DialogTitle>{t('pages:event:edit:dialog.questionbox-title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans i18nKey={"pages:event:edit:dialog.questionbox-description"} />
                        <TextField
                          label={t('pages:event:edit:dialog.questionbox-moderator')}
                          value={`https://prod-frontend-diaedge-ws.livestream-app.de/questionbox_admin/${originalData.id}?qba=${originalData.questionbox_moderation_secret}&m=moderator`}
                          disabled
                          fullWidth
                          variant={"outlined"}
                          style={{marginTop: '15px'}}
                        />
                        <TextField
                          label={t('pages:event:edit:dialog.questionbox-editor')}
                          value={`https://prod-frontend-diaedge-ws.livestream-app.de/questionbox_admin/${originalData.id}?qba=${originalData.questionbox_moderation_secret}&m=editor`}
                          disabled
                          fullWidth
                          variant={"outlined"}
                          style={{marginTop: '15px'}}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleQbClose}>
                        {t('globals:buttons:close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        ingest: state.singleEvent.ingest,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchIngest: (id) => dispatch(fetchSingleEventIngest(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventEdit);
