import React, {useEffect, useState} from "react";
import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import {Trans, useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    typeContainer: {
        marginBottom: '20px'
    },
    descriptionContainer: {
        marginBottom: '30px',
    }
}));

const Type = ({stepId, values, handleCallback}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const [type, setType] = useState(values?.type);
    const handleCheckbox = (e) => {
        setType(e.target.name);
    };

    useEffect(() => {
        if(type !== undefined && type !== "") {
            handleCallback(stepId, true, {type: type});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const types = [
        {
            title: t('pages:event:create:type:live.title'),
            description: t('pages:event:create:type:live.description'),
            name: "live"
        },
        {
            title: t('pages:event:create:type:external.title'),
            description: t('pages:event:create:type:external.description'),
            name: "external"
        },
        {
            title: t('pages:event:create:type:vod.title'),
            description: t('pages:event:create:type:vod.description'),
            name: "vod"
        },
    ]

    return (
        <div>
            <Typography variant={"subtitle1"} component={"p"} className={classes.descriptionContainer} >
                <Trans i18nKey={"pages:event:create:type:description"}/>
            </Typography>
            {types.map((elem, index) => {
                return (
                    <div className={classes.typeContainer} key={index}>
                        <Typography variant={"h4"} component={"h4"}>{elem.title}</Typography>
                        <Typography variant={"subtitle2"} component={"p"}>
                            {elem.description}
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={type === elem.name}
                                    onChange={handleCheckbox}
                                    name={elem.name}
                                    color="primary"
                                />
                            }
                            label={elem.title}
                        />
                    </div>
                )
            })}
        </div>
    )
};

export default Type;
