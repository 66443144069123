import React, {useState} from "react";
import {
  Button,
  Checkbox,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel, FormHelperText,
  TextField,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Trans, useTranslation} from "react-i18next";
import LanguageSelect from "../../../components/LanguageSelect";
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {useSnackbar} from "notistack";
import Api from "../../../utils/Api";
import {getToken} from "../../../helper/AuthHelper";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";

const useStyles = makeStyles(() => ({
    textField: {
        marginTop: '20px'
    },
    selectField: {
        marginTop: '20px',
        width: '100%'
    },
    button: {
        marginTop: '25px'
    }
}));

const Form = ({user, loginState}) => {

    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();

    const [state, setState] = useState(user);
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };
    const handleCheckbox = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.checked,
        });
    };

    const [deleted, setDeleted] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const handleOpen = () => {
        setDeleteOpen(true);
    };
    const handleClose = () => {
        setDeleteOpen(false);
    }

    const handleSubmit = async () => {
        try {
            const response = await Api.patch(`/v2/users/${user.id}`, {
                first_name: state?.first_name,
                last_name: state?.last_name,
                language: state?.language,
                company_name: state?.company_name,
                postalcode: state?.postalcode,
                city: state?.city,
            }, {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            });
            if(response.status === 200) {
                enqueueSnackbar(t('pages:user:edit:notifier-success'), {variant: "success"});
            }
        }
        catch(e) {
            console.debug("User mutation failed.", e);
            enqueueSnackbar(t('pages:user:edit:notifier-failure'), {variant: "warning"});
        }
    }

    const handleDelete = async () => {
        try {
            const response = await Api.delete(`/v2/users/${user.id}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            });
            if(response.status === 200){
                enqueueSnackbar(t('pages:user:edit:notifier-delete-success'),{variant: "success"});
                handleClose();
                setDeleted(true);
            }
        }
        catch(e) {
            console.debug("Deletion of user failed.", e);
            enqueueSnackbar(t('pages:user:edit:notifier-delete-failure'),{variant: "warning"});
        }
    }

    if(deleted === true) {
        return <Redirect to={"/users"} />
    }

    return (
        <div>
            <TextField
                label={t('pages:user:form.email')}
                helperText={t('pages:user:edit:form.email-help')}
                value={state.email}
                disabled
                fullWidth
            />
            <TextField
                name={"first_name"}
                label={t('pages:user:form.first_name')}
                value={state.first_name ?? ""}
                className={classes.textField}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                name={"last_name"}
                label={t('pages:user:form.last_name')}
                value={state.last_name ?? ""}
                onChange={handleChange}
                className={classes.textField}
                fullWidth
            />
            <TextField
                name={"company_name"}
                label={t('pages:user:form.company_name')}
                value={state.company_name ?? ""}
                onChange={handleChange}
                className={classes.textField}
                fullWidth
            />
            <TextField
                name={"postalcode"}
                label={t('pages:user:form.postalcode')}
                value={state.postalcode ?? ""}
                onChange={handleChange}
                className={classes.textField}
                fullWidth
            />
            <TextField
                name={"city"}
                label={t('pages:user:form.city')}
                value={state.city ?? ""}
                onChange={handleChange}
                className={classes.textField}
                fullWidth
            />
            <LanguageSelect className={classes.selectField} value={state.language} onChange={handleChange} labelTranslationKey={"pages:user:form.language"} />
            <FormControlLabel
                className={classes.textField}
                control={
                    <Checkbox
                        checked={state.active}
                        onChange={handleCheckbox}
                        name={"active"}
                        color="primary"
                        disabled={!loginState.user.roles.includes("ROLE_SUPERADMIN")}
                    />
                }
                label={t("pages:user:form.active")}
            />
            {!loginState.user.roles.includes("ROLE_SUPERADMIN") &&
              <FormHelperText>
                {t("pages:user:form.active_not_allowed_message")}
              </FormHelperText>
            }
            <br />
            <Button
                variant={"contained"}
                color={"primary"}
                startIcon={<SaveIcon />}
                className={classes.button}
                onClick={handleSubmit}
            >
                {t('globals:buttons:save-update')}
            </Button>
            <Button
                variant={"contained"}
                color={"secondary"}
                size={"small"}
                className={classes.button}
                startIcon={<DeleteForeverIcon />}
                onClick={handleOpen}
                style={{marginLeft: '10px'}}
            >
                {t('globals:buttons:delete')}
            </Button>

            <Dialog
                open={deleteOpen}
                onClose={handleClose}
            >
                <DialogTitle>{t('pages:user:edit:dialog.delete-title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans i18nKey={"pages:user:edit:dialog.delete-description"} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t('globals:buttons:cancel')}
                    </Button>
                    <Button onClick={handleDelete} color="secondary" variant={"contained"} autoFocus>
                        {t('globals:buttons:delete-forever')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => {
  return {
    loginState: state.login,
  };
}

export default connect(mapStateToProps, null)(Form);
