import React, {useState} from 'react';
import Template from "../../Base/Template";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {fetchEvents} from "../../../store/actions/eventsActions";
import {Box, Button, Step, StepLabel, Stepper} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {Link as RouterLink, Redirect} from "react-router-dom";
import Type from "./Steps/Type";
import Information from "./Steps/Information/Information";
import Summary from "./Steps/Summary";
import {useDebouncedEffect} from "../../../helper/useDebouncedEffect";
import Api from "../../../utils/Api";
import {getToken} from "../../../helper/AuthHelper";
import {useSnackbar} from "notistack";
import {DateTime} from "luxon";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginBottom: theme.spacing(2),
    },
    stepBox: {
        marginBottom: '30px',
        marginTop: '15px'
    },
    infoStepback: {
        fontStyle: 'italic',
        marginTop: '-10px'
    }
}));

const EventCreate = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const steps = [
        t('pages:event:create:steps.type'),
        t('pages:event:create:steps.metadata'),
        t('pages:event:create:steps.summary')
    ];
    const [activeStep, setActiveStep] = useState(0);
    const [globalValues, setGlobalValues] = useState({});
    const [creationState, setCreationState] = useState({
        created: false,
        error: false,
        id: null,
    });

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await Api.post("/v2/events", {
                title: globalValues?.title,
                slug: globalValues?.slug,
                description: globalValues?.description ?? "",
                starts_at: globalValues?.start_date?.toISO() ?? DateTime.now().toISO(),
                ends_at: globalValues?.end_date?.toISO(),
                ends_at_hidden: !globalValues?.end_date_hidden,
                type: globalValues?.type,
                tags: globalValues?.tags,
                language: globalValues?.language,
                external_url: globalValues?.external_url,
            }, {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            });

            if(response.status === 201) {
                setCreationState({
                    created: true,
                    error: false,
                    id: response.data.event.id,
                });
                enqueueSnackbar(t('pages:event:create:notifier-success', {variant: 'success'}));
            }
        }
        catch(e) {
            console.error(e);
            setCreationState({
                created: false,
                error: true,
                id: null
            });
        }

    };

    const [stepState, setStepState] = useState([
        {
            completed: false,
            values: {},
        },
        {
            completed: false,
            values: {},
        },
        {
            completed: true,
        },
    ]);
    const handleStepCallback = (stepId, state, values) => {
        let current = stepState;
        current[stepId] = {
            completed: state,
            values: values,
        };
        setStepState([...current]);

        console.debug(stepId, state, values);
    }

    useDebouncedEffect(() => {
        let newGlobalState = {};
        stepState.forEach((state) => {
           newGlobalState = {
               ...newGlobalState,
               ...state.values,
           };
        });
        setGlobalValues(newGlobalState);

        console.log(newGlobalState);
    }, [stepState], 200);

    const getActiveStep = (step) => {
        switch(step) {
            case 0:
                return <Type stepId={step} values={stepState[step].values} handleCallback={handleStepCallback} />;
            case 1:
                return <Information stepId={step} values={stepState[step].values} eventType={stepState[0].values.type} handleCallback={handleStepCallback} />;
            case 2:
                return <Summary stepId={step} globalValues={globalValues} handleCallback={handleStepCallback} creationState={creationState} />;
            default:
                return "Unknown step.";
        }
    }

    if(creationState.created === true) {
        return <Redirect to={"/events"} />;
    }

    return (
        <Template title={t('pages:event:create:title')}>
            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    className={classes.button}
                    startIcon={<ArrowBackIcon />}
                    size={"small"}
                    component={RouterLink}
                    to={"/events"}
                >
                    {t('globals:buttons:backtooverview')}
                </Button>
                <Paper className={classes.paper}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            return (
                                <Step key={index}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <Box className={classes.stepBox}>
                        {getActiveStep(activeStep)}
                    </Box>

                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Button
                            disabled={activeStep === 0 || creationState.created === true}
                            onClick={handleBack}
                            className={classes.button}
                            variant={"outlined"}
                            startIcon={<NavigateBeforeIcon />}
                        >
                            {t('pages:event:create:button.stepback')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={activeStep !== steps.length -1 ? handleNext : handleSubmit}
                            className={classes.button}
                            disabled={stepState[activeStep].completed !== true || creationState.created === true}
                            endIcon={activeStep === steps.length - 1 ? <CreditCardIcon /> : <NavigateNextIcon /> }
                        >
                            {activeStep === steps.length - 1 ? t('pages:event:create:button.create') : t('pages:event:create:button.continue')}
                        </Button>
                    </Box>
                    {activeStep > 0 && <span className={classes.infoStepback}>{t('pages:event:create:info.stepback')}</span> }
                </Paper>
            </Grid>
        </Template>
    );
};

const mapStateToProps = (state) => {
    return {
        eventList: state.events,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchEvents: () => dispatch(fetchEvents())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventCreate);
